import React, { useState,useEffect,useRef } from "react";

import { Link,useParams,useNavigate } from "react-router-dom";
import axios from "axios";

import Skalaton from "../../../dataTables/skalaton";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useHookstate } from '@hookstate/core'
import Store from '../../../../store';
import Moment from 'moment';
import {apPaymentAPI,apDoneSinglePaymentAPI,apGetSinglePaymentAPI,apVlUploadImageAPI} from "../../../../config/endpoints";
import "./style.css";
export default function ConfirmSingalPayment() {
  const params = useParams();
  const gState = useHookstate(Store);
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [carrierData, setCarrierData] = useState({});
  const [image, setImage] = useState();
  const [desc, setDesc] = useState();
  const [method, setMethod] = useState();
  const [methodError, setError] = useState();
  const [progressDisplay, setProgressDisplay] = useState('d-none');
  const [imageName, setImageName] = useState();
  const progress = useRef();

  const date = gState.fullDate.get();
  useEffect(() => {
    
    setData([]);
      axios.post(apGetSinglePaymentAPI,{
          withCredentials:true,
          id:params.id,
      })
        .then(function (response) {
          setData(response.data.data)
          setCarrierData(response.data.carrierData)
          // carrierData
          
        }).catch(function (error) {
          console.log(error);
        });

      },[]);
      
      const handleFileUpload = (event) => {
        setProgressDisplay('d-block');

        // get the selected file from the input
        const file = event.target.files[0];
        // create a new FormData object and append the file to it
        const formData = new FormData();
        formData.append("image", file);
        // make a POST request to the File Upload API with the FormData object and Rapid API headers
        axios
          .post(apVlUploadImageAPI, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "x-rapidapi-host": "file-upload8.p.rapidapi.com",
              "x-rapidapi-key": "your-rapidapi-key-here",
            },
            onUploadProgress: (progressEvent) => {
              let width = ((progressEvent.loaded*100)/progressEvent.total);
              progress.current.style.width = width+'%';
              progress.current.innerHTML = width+'%';
            }
        })
          .then((response) => {
            setImageName(response.data);
            setProgressDisplay('d-none');
          })
          .catch((error) => {
            // handle errors
            
            console.log(error);
          });
      }
      const Payment = ()=>{

        axios.post(apDoneSinglePaymentAPI,{
            withCredentials:true,
            id:params.id,
            desc:desc,
            method:method,
            image:imageName
        })
        .then(function (response) {
          console.log(response.data);
          navigate("/admin/ap/carrier-payment");
        }).catch(function (error) {
          console.log(error);
          setError(error.response.data);
        });
      }

  return (
            <div className="all_pages_body mb-100" >
            

              <div className='container pt-5 '>
              
                <div className="card bg-white">
                  <div className="card-body">
                    
                    <div className="row">
                      <div className="col-md-6 text-start"> 
                      <ToastContainer />
                          <h3 className="main-title pt-1">Update Carrier Payment</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='container mt-1 py-1'>
                
                <div className="row">
                  <div className="col-12">
                    <div className="card border-0 shadow">
                      <div className="card-body  bg-white rounded ">
                          <div className="container shadow  py-2">
                              <div className="table-responsive">
                                  <table className="table table0 accordion">
                                  {(data.primery_method == 'ACH')?<>
                                    <thead>
                                            <tr>
                                              

                                                <th>Pro#</th>
                                                <th>Payment Method</th>
                                                
                                                <th>Invoice No.</th>
                                                <th>Invoice Date</th>
                                                <th>Ages</th>
                                                <th>currency Type</th>
                                                {(data.advancePay_ap_amount>0)?<th>Advanced Pay</th>:''}
                                                {(data.quickpay== '1')?<th>Total Amount</th>:''}
                                                <th>Payable Amount</th>
                                                <th>Bank Name</th>
                                                <th>Account Number</th>
                                                <th>Account Type</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                    { (data.length<1)?
                                    <Skalaton rows="1" cols="10" />
                                    :
                                        <tr >
                                            <td>PRO {data.shipment_Id}</td>
                                            <td>{data.primery_method!=null?data.primery_method:'N/A'}</td>
                                            {/* <td>{ data.payto=="1"?data.f_companyName:(data.payto=="2"?data.c_company_name:(data.company_name!=null?data.company_name:'N/A'))}</td>  */}
                                            {/* <td>{data.first_name!=null?data.first_name:data.brokername}</td> */}
                                            
                                            <td>Invoice {data.invoice_num}</td>
                                            <td>{data.invoice_date}</td>
                                            <td>{data.Age_date}</td>
                                            <td>{data.payment_mode=="USD" || data.primery_method == "ACH"?(' USD'):' CAD' }</td>
                                            
                                            {(data.advancePay_ap_amount>0)?<th>{data.advancePay_ap_amount}</th>:''}
                                            {(data.quickpay== '1')?<th>{data.carrier_total}</th>:''}
                                            <td>${data.payment_mode=="USD" || data.primery_method == "ACH"?(data.payable_amount):((data.payable_amount_cad!=null)?data.payable_amount_cad:data.carrier_total_cad) }</td>
                                            <td>{data.bank_name}</td>
                                            <td>{data.account_number}</td>
                                            <td>{data.b_account_type}</td>
                                        </tr>}

                                        <tr>
                                          <th>ACH Routing Number</th>
                                          <th>Bank Country</th>
                                          <th colSpan={(data.advancePay_ap_amount>0)?'1':"2"}>Company Name</th>
                                          <th>Remittance Address</th>
                                          <th>City</th>
                                          <th>State</th>
                                          <th>ZipCode</th>
                                          <th colSpan={(data.quickpay== '1')?'2':"1"}>Phone Number</th>
                                          <th>Email Address</th>
                                        </tr>
                                        { (carrierData.length<1)?
                                    <Skalaton rows="1" cols="10" />
                                    :
                                        <tr>
                                    
                                        <td>{carrierData.ach_routing_number}</td>
                                        <td  style={(carrierData.ach_b_country == "Cad Bank")?{backgroundColor:"#da111173;font-weight: 800"}:{}}
                                        >{carrierData.ach_b_country}</td>
                                        {(carrierData.payto == '' || carrierData.payto == '0')?<>
                                        
                                          <td colSpan={(data.advancePay_ap_amount>0)?'1':"2"}>{carrierData.company_name}</td>
                                          <td>{carrierData.carrier_city}</td>
                                          <td>{carrierData.carrier_city_main}</td>
                                          <td>{carrierData.carrier_state}</td>
                                          <td>{carrierData.carrier_zip}</td>
                                          <td colSpan={(data.quickpay== '1')?'2':"1"}>{carrierData.phone_no}</td>
                                          <td>{carrierData.email}</td> </>:''}
                                        {(carrierData.payto == '1')?<>
                                          <td colSpan={(data.advancePay_ap_amount>0)?'1':"2"}>{carrierData.f_companyName}</td>
                                          <td>{carrierData.f_address}</td>
                                          <td>{carrierData.f_city}</td>
                                          <td>{carrierData.f_state}</td>
                                          <td>{carrierData.f_zip}</td>
                                          <td colSpan={(data.quickpay== '1')?'2':"1"}>{carrierData.f_phone}</td>
                                          <td>{carrierData.f_email}</td> </>:''}
                                        {(carrierData.payto == '2')?<>
                                          <td colSpan={(data.advancePay_ap_amount>0)?'1':"2"}>{carrierData.c_company_name}</td>
                                          <td>{carrierData.c_address}</td>
                                          <td>{carrierData.c_city}</td>
                                          <td>{carrierData.c_state}</td>
                                          <td>{carrierData.c_zip}</td>
                                          <td colSpan={(data.quickpay== '1')?'2':"1"}>{carrierData.c_phone}</td>
                                          <td>{carrierData.c_email}</td></>:''}
                                        </tr>}
                                    </tbody>
                                    </>:''}
                                    {(data.primery_method == 'cheque')?
                                    <>
                                    <thead>
                                            <tr>
                                                <th>Pro#</th>
                                                <th>Payment Method</th>
                                                <th>Invoice No.</th>
                                                <th>Invoice Date</th>
                                                <th>Ages</th>
                                                <th>currency Type</th>
                                                {(data.advancePay_ap_amount>0)?<th>Advanced Pay</th>:''}
                                                {(data.quickpay== '1')?<th>Total Amount</th>:''}
                                                <th>Payable Amount</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                    { (data.length<1)?
                                    <Skalaton rows="1" cols="10" />
                                    :
                                        <tr >
                                            <td>PRO {data.shipment_Id}</td>
                                            <td>{data.primery_method!=null?data.primery_method:'N/A'}</td>
                                            {/* <td>{ data.payto=="1"?data.f_companyName:(data.payto=="2"?data.c_company_name:(data.company_name!=null?data.company_name:'N/A'))}</td>  */}
                                            {/* <td>{data.first_name!=null?data.first_name:data.brokername}</td> */}
                                            
                                            <td>Invoice {data.invoice_num}</td>
                                            <td>{data.invoice_date}</td>
                                            <td>{data.Age_date}</td>
                                            <td>{data.payment_mode=="USD" || data.primery_method == "ACH"?(' USD'):' CAD' }</td>
                                            
                                            {(data.advancePay_ap_amount>0)?<th>{data.advancePay_ap_amount}</th>:''}
                                            {(data.quickpay== '1')?<th>{data.carrier_total}</th>:''}
                                            <td>${data.payment_mode=="USD" || data.primery_method == "ACH"?(data.payable_amount):((data.payable_amount_cad!=null)?data.payable_amount_cad:data.carrier_total_cad) }</td>
                                        </tr>}

                                        <tr>
                                          <th colSpan="2">Company Name</th>
                                          <th colSpan="2" >Remittance Address</th>
                                          <th>City</th>
                                          <th>State</th>
                                          <th>ZipCode</th>
                                          <th colSpan="2" >Phone Number</th>
                                          <th>Email Address</th>
                                        </tr>
                                        { (carrierData.length<1)?
                                    <Skalaton rows="1" cols="10" />
                                    :
                                        <tr>
                                    
                                        <td>{carrierData.ach_routing_number}</td>
                                        <td  style={(carrierData.ach_b_country == "Cad Bank")?{backgroundColor:"#da111173;font-weight: 800"}:{}}
                                        >{carrierData.ach_b_country}</td>
                                        {(carrierData.payto == '' || carrierData.payto == '0')?<>
                                        
                                          <td colSpan="2" >{carrierData.company_name}</td>
                                          <td colSpan="2">{carrierData.carrier_city}</td>
                                          <td>{carrierData.carrier_city_main}</td>
                                          <td>{carrierData.carrier_state}</td>
                                          <td>{carrierData.carrier_zip}</td>
                                          <td colSpan="2">{carrierData.phone_no}</td>
                                          <td colSpan="2">{carrierData.email}</td> </>:''}
                                        {(carrierData.payto == '1')?<>
                                          <td colSpan="2">{carrierData.f_companyName}</td>
                                          <td colSpan="2">{carrierData.f_address}</td>
                                          <td>{carrierData.f_city}</td>
                                          <td>{carrierData.f_state}</td>
                                          <td>{carrierData.f_zip}</td>
                                          <td colSpan="2">{carrierData.f_phone}</td>
                                          <td colSpan="2">{carrierData.f_email}</td> </>:''}
                                        {(carrierData.payto == '2')?<>
                                          <td colSpan="2">{carrierData.c_company_name}</td>
                                          <td colSpan="2">{carrierData.c_address}</td>
                                          <td>{carrierData.c_city}</td>
                                          <td>{carrierData.c_state}</td>
                                          <td>{carrierData.c_zip}</td>
                                          <td colSpan="2">{carrierData.c_phone}</td>
                                          <td colSpan="2">{carrierData.c_email}</td></>:''}
                                        </tr>}
                                    </tbody></>:''}


                                    {(data.primery_method == 'Wire')?<>
                                    <thead>
                                            <tr>
                                              

                                                <th>Pro#</th>
                                                <th>Payment Method</th>
                                                
                                                <th>Invoice No.</th>
                                                <th>Invoice Date</th>
                                                <th>Ages</th>
                                                <th>currency Type</th>
                                                {(data.advancePay_ap_amount>0)?<th>Advanced Pay</th>:''}
                                                <th>Payable Amount</th>
                                                <th>Bank Name</th>
                                                <th>Account Number</th>
                                                <th>Account Type</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                    { (data.length<1)?
                                    <Skalaton rows="1" cols="9" />
                                    :
                                        <tr >
                                            <td>PRO {data.shipment_Id}</td>
                                            <td>{data.primery_method!=null?data.primery_method:'N/A'}</td>
                                            {/* <td>{ data.payto=="1"?data.f_companyName:(data.payto=="2"?data.c_company_name:(data.company_name!=null?data.company_name:'N/A'))}</td>  */}
                                            {/* <td>{data.first_name!=null?data.first_name:data.brokername}</td> */}
                                            
                                            <td>Invoice {data.invoice_num}</td>
                                            <td>{data.invoice_date}</td>
                                            <td>{data.Age_date}</td>
                                            <td>{data.payment_mode=="USD" || data.primery_method == "ACH"?(' USD'):' CAD' }</td>
                                            {(data.advancePay_ap_amount>0)?<th>{data.advancePay_ap_amount}</th>:''}
                                            <td>${data.payment_mode=="USD" || data.primery_method == "ACH"?(data.payable_amount):((data.payable_amount_cad!=null)?data.payable_amount_cad:data.carrier_total_cad) }</td>
                                            <td>{data.bank_name}</td>
                                            <td>{data.account_number}</td>
                                            <td>{data.b_account_type}</td>
                                        </tr>}

                                        <tr>
                                          <th>ACH Routing Number</th>
                                          <th>Swift Code</th>
                                          <th>Bank Country</th>
                                          <th>Company Name</th>
                                          <th>Remittance Address</th>
                                          <th>City</th>
                                          <th>State</th>
                                          <th>ZipCode</th>
                                          <th>Phone Number</th>
                                          <th>Email Address</th>
                                        </tr>
                                        { (carrierData.length<1)?
                                    <Skalaton rows="1" cols="10" />
                                    :
                                        <tr>
                                    
                                        <td>{carrierData.ach_routing_number}</td>
                                        <td>{carrierData.swift_code}</td>
                                        <td  style={(carrierData.ach_b_country == "Cad Bank")?{backgroundColor:"#da111173;font-weight: 800"}:{}}
                                        >{carrierData.ach_b_country}</td>
                                        {(carrierData.payto == '' || carrierData.payto == '0')?<>
                                        
                                          <td>{carrierData.company_name}</td>
                                          <td>{carrierData.carrier_city}</td>
                                          <td>{carrierData.carrier_city_main}</td>
                                          <td>{carrierData.carrier_state}</td>
                                          <td>{carrierData.carrier_zip}</td>
                                          <td colSpan="2">{carrierData.phone_no}</td>
                                          <td>{carrierData.email}</td> </>:''}
                                        {(carrierData.payto == '1')?<>
                                          <td>{carrierData.f_companyName}</td>
                                          <td>{carrierData.f_address}</td>
                                          <td>{carrierData.f_city}</td>
                                          <td>{carrierData.f_state}</td>
                                          <td>{carrierData.f_zip}</td>
                                          <td colSpan="2">{carrierData.f_phone}</td>
                                          <td>{carrierData.f_email}</td> </>:''}
                                        {(carrierData.payto == '2')?<>
                                          <td>{carrierData.c_company_name}</td>
                                          <td>{carrierData.c_address}</td>
                                          <td>{carrierData.c_city}</td>
                                          <td>{carrierData.c_state}</td>
                                          <td>{carrierData.c_zip}</td>
                                          <td colSpan="2">{carrierData.c_phone}</td>
                                          <td>{carrierData.c_email}</td></>:''}
                                        </tr>}
                                    </tbody>
                                    </>:''}

                                    {(data.primery_method == 'credit_card')?<>
                                    <thead>
                                            <tr>
                                              

                                                <th>Pro#</th>
                                                <th>Payment Method</th>
                                                
                                                <th>Invoice No.</th>
                                                <th>Invoice Date</th>
                                                <th>Ages</th>
                                                <th>currency Type</th>
                                                {(data.advancePay_ap_amount>0)?<th>Advanced Pay</th>:''}
                                                <th>Payable Amount</th>
                                                
                                            </tr>
                                    </thead>
                                    <tbody>
                                    { (data.length<1)?
                                    <Skalaton rows="1" cols="10" />
                                    :
                                        <tr >
                                            <td>PRO {data.shipment_Id}</td>
                                            <td>{data.primery_method!=null?data.primery_method:'N/A'}</td>
                                            <td>Invoice {data.invoice_num}</td>
                                            <td>{data.invoice_date}</td>
                                            <td>{data.Age_date}</td>
                                            <td>{data.payment_mode=="USD" || data.primery_method == "ACH"?(' USD'):' CAD' }</td>
                                            {(data.quickpay== '1')?<th>{data.carrier_total}</th>:''}
                                            <td>${data.payment_mode=="USD" || data.primery_method == "ACH"?(data.payable_amount):((data.payable_amount_cad!=null)?data.payable_amount_cad:data.carrier_total_cad) }</td>
                                            
                                        </tr>}
                                        <tr>
                                          <th>Company Name</th>
                                          <th>Remittance Address</th>
                                          <th>City</th>
                                          <th>State</th>
                                          <th>ZipCode</th>
                                          <th>Phone Number</th>
                                          <th>Email Address</th>
                                        </tr>
                                        { (carrierData.length<1)?
                                        <Skalaton rows="1" cols="10" />
                                        :
                                        <tr>
                                        {(carrierData.payto == '' || carrierData.payto == '0')?<>
                                        
                                          <td colSpan="2">{carrierData.company_name}</td>
                                          <td colSpan="2">{carrierData.carrier_city}</td>
                                          <td>{carrierData.carrier_city_main}</td>
                                          <td>{carrierData.carrier_state}</td>
                                          <td>{carrierData.carrier_zip}</td>
                                          <td colSpan="2">{carrierData.phone_no}</td>
                                          <td colSpan="2">{carrierData.email}</td> </>:''}
                                        {(carrierData.payto == '1')?<>
                                          <td colSpan="2">{carrierData.f_companyName}</td>
                                          <td colSpan="2">{carrierData.f_address}</td>
                                          <td>{carrierData.f_city}</td>
                                          <td>{carrierData.f_state}</td>
                                          <td>{carrierData.f_zip}</td>
                                          <td colSpan="2">{carrierData.f_phone}</td>
                                          <td colSpan="2">{carrierData.f_email}</td> </>:''}
                                        {(carrierData.payto == '2')?<>
                                          <td colSpan="2">{carrierData.c_company_name}</td>
                                          <td colSpan="2">{carrierData.c_address}</td>
                                          <td>{carrierData.c_city}</td>
                                          <td>{carrierData.c_state}</td>
                                          <td>{carrierData.c_zip}</td>
                                          <td colSpan="2">{carrierData.c_phone}</td>
                                          <td colSpan="2">{carrierData.c_email}</td></>:''}
                                        </tr>}
                                    </tbody>
                                    </>:''}
                                  </table>
                              </div>
                          </div>
                          
                      </div>
                    </div>
                  </div>
                </div>
            </div>
              <div className='container mt-3 py-3'>
                
                  <div className="row">
                    <div className="col-12">
                      <div className="card border-0 shadow">
                        <div className="card-body  bg-white rounded ">
                        <form action="javascript:void(0)"> 
                          <div className="row">
                            <div className="col col-md-12 text-center mb-2 text-capitalize">
                              <label className="text-danger">{methodError}</label>
                                
                            </div>
                            <div className="col col-md-6">
                              <select type="text" rows="5 " className="form-control mb-2"  placeholder="Method"    onChange={(e)=>{setMethod(e.target.value)}} >
                                      <option value="">Select Method</option>
                                      <option value="ACH">ACH</option>
                                      <option value="Wire">Wire</option>
                                      <option value="Check">Check</option>
                                      <option value="EFS">EFS</option>
                              </select>
                                <textarea type="text" rows="3" className="form-control" id="exampleInputEmail1" placeholder="Note"  style={{"border": "3px dashed #dee2e6"}}   onChange={(e)=>{setDesc(e.target.value)}} >
                                {desc}
                              </textarea>
                            </div>
                            <div className="col col-md-6">
                                <div className="  text-center p-3 rounded" style={{"border": "3px dashed #dee2e6"}}>
                                  <h4 className=" fw-bold" for="">Drop File Here</h4>
                                  <small className="fs-5" for="">or</small>
                                  <div className={'progress '+progressDisplay} >
                                    <div ref={progress} className="progress-bar" role="progressbar"   aria-valuenow='25' aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>
                                  <div className="w-75  m-auto">
                                    <input type="file" className="form-control" id="inputGroupFile01" onChange={handleFileUpload} />
                                  </div>
                                </div>

                            </div>
                            <div class="row">
                              <div class="col col-md-12 p-0 pt-3  ">
                                <button  onClick={()=>{Payment()}} className="btn btn-outline-success px-5 float-end">Confirm</button>
                              </div>
                            </div>
                          </div>

                        </form>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
             
            </div>
  )
}


