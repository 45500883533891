import React, { Component } from "react";
import Chart from "react-apexcharts";

import ApexCharts from 'apexcharts';
class LineChart extends Component {
  constructor(props) {
    super(props);
    
    // console.log(this.props.series);
    // console.log(this.props.labels);
    this.state = {
         options: {
          chart: {
            id:'abc',
            height: 280,
            type: "line",
            toolbar: {
              show: false,
            }
            
          },
          colors:['#73d298'],
          labels:this.props.labels,
          fill: {
            type: 'solid'
          },
          stroke: {
            curve: 'straight',
          },
          dataLabels: {
            enabled: false
          },
          series: [
            {
              type:'line',
              show: false,
              data: [],
            }
          ],
          
        markers: {
          size: 3,
          colors: ["#338a9b"],
          strokeColor: "#338a9b",
          strokeWidth:2
        },
          xaxis: {
            labels: {
              show: false,
            }, 
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            }
          },
          yaxis: {
            labels: {
              show: false,
              formatter: function (value) {
                return '$' + Number(value).toLocaleString('en-IN');
              }
            }
           
          },
         
          tooltip: {
            enabled: true,
          },
          grid: {
            show: true,
            strokeDashArray: 7,
            xaxis: {
              lines: { 
                show: true  //or just here to disable only y axis
               }
             },
            yaxis: {
              lines: { 
                show: false  //or just here to disable only y axis
               }
             },
          },
          
          


        }
      };
    }

    componentDidUpdate(prevProps) {
      if (prevProps.series !== this.props.series) {

        this.setState({
          options: {
            ...this.state.options,
            labels:  this.props.labels
          }
        })
        
      }
    }
  render() {
   
    return (
      <>
      
                <Chart
                options={this.state.options}
                series={this.props.series}
                reRender="charts"
                type="area" 
                height="150px"
                style={{ "marginTop": "-20px"}}

                />
      </>
    );
  }
}
 
export default LineChart;