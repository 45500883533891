import React, { Component } from "react";
import Chart from "react-apexcharts";

class DonutHalf extends Component {
  constructor(props) {
    super(props);
    this.state = {
         options: {
          chart: {
            height: 100,
            type: "radialBar",
            
          },
          
          
          colors: ["#FF9800"],
          plotOptions: {
            
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                // margin: '5px',
                size: "70%"
              },
              track: {
                background: '#e0e0e3',
                startAngle: -90,
                endAngle: 90,
              },
              dataLabels: {
                name: {
                  show: true,
                },
                value: {
                  offsetY: -5,
                  fontSize: "20px",
                  // formatter:(val,i)=>{console.log(i)},
                  show: false
                }
              }
            }
          },
         
          // fill: {
          //   type: "gradient",
          //   gradient: {
          //     shade: "dark",
          //     type: "horizontal",
          //     gradientToColors: ["#87D4F9"],
          //     stops: [0, 100]
          //   }
          // },
          stroke: {
            lineCap: "round"
          },
          labels: this.props.labels
        }
      };
    }

  render() {
    function series(p_data){
     let data = p_data;
     data.forEach(function(item,i){
      data[i] = parseInt((item<1?1:item));
     });
     return data;
    }
    return (
      <>
      
                <Chart
                options={this.state.options}
                series={this.props.series}
                // labels={'sdsds'}
                type="radialBar"
                height="200px"
                style={{ "marginTop": "-20px"}}

                />
      </>
    );
  }
}
 
export default DonutHalf;