import React, { Component } from "react";
import Chart from "react-apexcharts";

class MarginReport extends Component {
  constructor(props) {
    super(props);
    // console.log(this.props.series?.series);
    this.state = {
      options: {
        
          colors: ['#f76e5f', '#9e63f0', '#73d298','#486e76'],
            chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            },
            toolbar: {
              show: false,
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [5, 7, 5],
            curve: 'straight',
            dashArray: [0, 8, 5]
          },
          // title: {
          //   show: false,
          //   text: 'Page Statistics',
          //   align: 'left'
          // },
          legend: {
            tooltipHoverFormatter: function(val, opts) {
              return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
            }
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          xaxis: {
            categories: this.props.labels
          },
          
          yaxis: {
            labels:
            {
              formatter: function (value) {
                return '$' + Number(value).toLocaleString('en-IN');
              }
            }
          },

          tooltip: {
             
           
            y: [
              
              {
                title: {
                  formatter: function (val) {
                    return val + ""
                  }
                }
              },
              {
                title: {
                  formatter: function (val) {
                    return val + ""
                  }
                }
              },
              {
                title: {
                  formatter: function (val) {
                    return val;
                  }
                }
              }
            ]
          },
          grid: {
            borderColor: '#f1f1f1',
          }
          }
      };
    }

    componentDidUpdate(prevProps) {
      if (prevProps.series !== this.props.series) {

        this.setState({
          options: {
            ...this.state.options,
            xaxis: {
              ...this.state.options.xaxis,
              categories:  this.props.labels
            }
          }
        })
        
      }
    }

  render() {
    function series(p_data){
     let data = p_data;
     data.forEach(function(item,i){
      data[i] = parseInt((item<1?1:item));
     });
     return data;
    }
    return (
      <>
      <div className="app">
        <div className="row">
          <div className="card border-0">
            <div className="card-body p-0">
                <Chart
                options={this.state.options}
                series={this.props.series?.series??[]}
                type="line"
                height={this?.props?.height}
                />
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}
 
export default MarginReport;