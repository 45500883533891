import React, { Component } from "react";
import Chart from "react-apexcharts";

class Colomn extends Component {
  constructor(props) {
    super(props);
    this.state = {
         options: {
          colors: ["#f76e5f","#9e63f0"],
          series: [{
            name:'Received',
            data: [ 13, 10, 22, 28, 21,  21,  30, 16,  13, 21, 30,13]
          },{
            name:'Payout',
            data: [21, 22, 10,  16,28, 21, 13, 30, 21, 16, 13,30]
          }],
          chart: {
          height: 350,
          type: 'bar',
          events: {
            click: function(chart, w, e) {
              // console.log(chart, w, e)
            }
          },
          toolbar: {
            show: false,
          }
        },
        plotOptions: {
          bar: {
            
            borderRadius: 5,
            borderRadiusApplication: 'end',
            borderRadiusWhenStacked: 'last',
            columnWidth: '60%',
            // distributed: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
          'October', 'November', 'December'
        ],
          labels: {
            style: {
              colors: ["#f76e5f","#9e63f0","#73d298","#338a9b","#f76e5f","#9e63f0","#73d298","#338a9b","#f76e5f","#9e63f0","#73d298","#338a9b"],
              fontSize: '12px'
            }
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return '$' + Number(value).toLocaleString('en-IN');
            }
          }
        }
        
        ,grid: {
          show: true,
          strokeDashArray: 7,
          xaxis: {
            lines: { 
              show: false  //or just here to disable only y axis
             }
           },
          yaxis: {
            lines: { 
              show: false  //or just here to disable only y axis
             },
             
           },
           
        },

        }
    };
    
    }

    componentDidUpdate(prevProps) {
      if (prevProps.series !== this.props.series) {

        this.setState({
          options: {
            ...this.state.options,
            xaxis: {
              ...this.state.options.xaxis,
              categories:  this.props.labels
            }
          }
        })
        
      }
    }
  render() {
    function series(p_data){
     let data = p_data;
     data.forEach(function(item,i){
      data[i] = parseInt((item<1?1:item));
     });
     return data;
    }
    return (
      <>
      
                <Chart
                options={this.state.options}
                series={this.props.series}
                type="bar"
                height="300px"
                // style={{ "marginTop": "-20px"}}

                />
      </>
    );
  }
}
 
export default Colomn;