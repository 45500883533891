import React, { Component } from "react";
//import "../../../pages/report/report.css";
import "../css/all_margin_report.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {faSatellite } from '@fortawesome/free-solid-svg-icons';
<FontAwesomeIcon icon="fa-brands fa-nfc-symbol" />
const CashFlow = ({icon,title,price,iconColor})=> {

    return (
        <div>
            <div className="card all_margin_card">
                <div className="card-body">
                    <div className="app">
                        <div className="row">
                            <div className="mixed-chart">
                              <div className="d-flex">
                                <div className="flex-shrink-0 pt-2">
                                  <span className={iconColor+' fs-3'}>
                                      <FontAwesomeIcon icon={icon} />
                                  </span>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="text-muted mb-1 box_title">{title}</p>
                                  <div className="py-1">
                                    <h4>$ {price} </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CashFlow;
