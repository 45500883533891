import React, { useState,useEffect } from "react";
import {addQuoteData} from "../../config/endpoints";
import axios from "axios";
import { useNavigate,useParams } from "react-router-dom";

import "./quotes.css";
import DatePicker from "react-datepicker";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const AddQuote = () => {
    
  const params = useParams();
  const token = params.token;
  const navigate = useNavigate();
  
//   const [departureDate, setDepartureDate] = useState(new Date()); 
//   const [departureDate1, setDepartureDate1] = useState(new Date()); 
  const [userId, setUserId] = useState([]);

  const [formErrors, setFormErrors] = useState({});

  const [addQuote, setAddQuote] = useState({
    departure_date: new Date(),
    departure_date1: new Date(),
    originating_at_pa:"",
    originating_at_dp: "",
    originating_at_dp_details:"",
    originating_at_pa_details:"",
    delivery_at_dp: "",
    delivery_at_dp_details: "",
    delivery_at_pa: "",
    delivery_at_pa_details: "",
    no_of_containers: "",
    container_size:"",
    container_type: "",
    temprature: "",
    container_weight: "",
    comment: "",
    commodity: "",
    load_type: "LCL",
    user_id : "",
    token:token

  });
 
  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setAddQuote({ ...addQuote, [name]: value });
  };

  const validateFields = (values) => {
    var error = false;
    let msgs = {};
    if (!values.originating_at_pa) {
        msgs.originating_at_pa = 'This field is Required ';
      error = true;
    }
    if (!values.originating_at_dp) {
        msgs.originating_at_dp = 'This field is Required ';
        error = true;
    }
    if (!values.originating_at_dp_details) {
        msgs.originating_at_dp_details = 'This field is Required ';
      error = true;
    }
    if (!values.originating_at_pa_details) {
        msgs.originating_at_pa_details = 'This field is Required ';
      error = true;
    }
    if (!values.delivery_at_dp) {
        msgs.delivery_at_dp = 'This field is Required ';
      error = true;
    }
    if (!values.delivery_at_dp_details) {
        msgs.delivery_at_dp_details = 'This field is Required ';
      error = true;
    }
    if (values.delivery_at_pa == '') {
        msgs.delivery_at_pa = 'This field is Required ';
      error = true;
    }
    if (!values.delivery_at_pa_details) {
        msgs.delivery_at_pa_details = 'This field is Required ';
      error = true;
    }
    if (!values.no_of_containers) {
        msgs.no_of_containers = 'This field is Required ';
      error = true;
    }
    if (!values.container_size) {
        msgs.container_size = 'This field is Required ';

      error = true;
    }
    if (!values.load_type) {
        msgs.load_type = 'This field is Required ';
      error = true;
    }
    if (!values.commodity) {
        msgs.commodity = 'This field is Required ';
      error = true;
    }
    if (!values.container_type) {
        msgs.container_type = 'This field is Required ';
      error = true;
    }
    if (!values.container_weight) {
        msgs.container_weight = 'This field is Required ';
      error = true;
    }
    if (!values.temprature) {
        msgs.temprature = 'This field is Required ';
      error = true;
    }
    
    
    if(error){
        setFormErrors(msgs);
    }else{
        setFormErrors({});
    }
   console.log(msgs);
    return error;
  };



  const submitForm = async (e) => {
    // alert('aa');
    e.preventDefault();
    let isValidate = validateFields(addQuote);

    const uid = localStorage.getItem('user_id');
    if (uid) {
      addQuote.user_id = uid;
      if(!isValidate){
        await axios
          .post(addQuoteData, addQuote, {
            withCredentials:true,
          }).then((res) => {
            if(res.status==200){
              toast.success("Quote Added Successfully!", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            const delayfrfresh = () => {
              navigate(`/quotes/${token}`)
            };
            // eslint-disable-next-line
            const delayRef = setTimeout(delayfrfresh, 3100);
      
          })
          .catch((error) => {
            toast.error("Something Went Wrong! Please Try Again Later", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        });
      }else{
        toast.error("Please Fill Form Fields!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    
    
  };
}
 
  return (
    <>
    <div className="main py-3 home-body" > 
        <section >
            <div className="container">
                <div className="row">
                <ToastContainer />
                </div>
                <div className="row">
                    <div className=" mt-20 bg-white rounded-xl w-[70%] mx-auto shadow-xl py-10 ">
                        <div className=" p-4  ">
                            <form class="row g-3" onSubmit={submitForm}>
                                <div className="col-md-3">
                                    <label for="inputState" class="form-label">Originating Door OR Port</label>
                                    <select id="inputState" class="form-select" name ="originating_at_dp" value={addQuote.originating_at_dp}  onChange={handleInput}  >
                                        <option value="">Select </option>
                                        <option value="door">Door</option>
                                        <option value="port">Port</option>
                                    </select>
                                    
                                    <span className="text-danger"> {formErrors.originating_at_dp??''}</span>
                                </div>
                                <div className="col-md-3">
                                    <label for="inputState" class="form-label">Originating Port Or Address</label>
                                    <select id="inputState" class="form-select" name ="originating_at_pa" value={addQuote.originating_at_pa}  onChange={handleInput} >
                                        <option value="">Select </option>
                                        <option value="door">Door</option>
                                        <option value="address">Address</option>
                                    </select>
                                    <span className="text-danger"> {formErrors.originating_at_pa??''}</span>
                                </div>

                                <div className="col-md-3">
                                    <label for="inputState" class="form-label">Delivery Door Or Port</label>
                                    <select id="inputState" class="form-select" name ="delivery_at_dp" value={addQuote.delivery_at_dp}  onChange={handleInput}  >
                                        <option value="">Select </option>
                                        <option value="door">Door</option>
                                        <option value="port">Port</option>
                                    </select>
                                    
                                    <span className="text-danger"> {formErrors.delivery_at_dp??''}</span>
                                </div>

                                <div className="col-md-3">
                                    <label for="inputState" class="form-label">Delivery Address Or Port</label>
                                    <select id="inputState" class="form-select" name ="delivery_at_pa" value={addQuote.delivery_at_pa}  onChange={handleInput} >
                                        <option value="">Select </option>
                                        <option value="address">Address</option>
                                        <option value="port">Port</option>
                                    </select>
                                    <span className="text-danger"> {formErrors.delivery_at_pa??''}</span>

                                </div>
                                <div class="col-md-3">
                                    <label for="inputEmail4" class="form-label">Orginating Details</label>
                                    <textarea className=" form-control shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        type="text" placeholder="Orginating Details"
                                        name="originating_at_dp_details"
                                        value={addQuote.originating_at_dp_details}
                                        onChange={handleInput}
                                    />
                                <span className="text-danger"> {formErrors.originating_at_dp_details??''}</span>
                                </div>
                                <div class="col-md-3">
                                    <label for="inputPassword4" class="form-label">Orginating Details</label>
                                        <textarea className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        type="text" placeholder="Orginating Details"
                                        name="originating_at_pa_details"
                                        value={addQuote.originating_at_pa_details}
                                        onChange={handleInput}
                                        />
                                    <span className="text-danger"> {formErrors.originating_at_pa_details??''}</span>

                                </div>
                                <div class="col-3">
                                    <label for="inputAddress" class="form-label">Delivery Details</label>
                                        <textarea className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        type="text" placeholder="Delivery Details"
                                        name="delivery_at_dp_details"
                                        value={addQuote.delivery_at_dp_details}
                                        onChange={handleInput}
                                        />
                                    <span className="text-danger"> {formErrors.delivery_at_dp_details??''}</span>
                                </div>
                                <div class="col-3">
                                    <label for="inputAddress" class="form-label">Delivery Details</label>
                                        <textarea className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        type="text" placeholder="Delivery Details"
                                        name="delivery_at_pa_details"
                                        value={addQuote.delivery_at_pa_details}
                                        onChange={handleInput}
                                        />
                                    <span className="text-danger"> {formErrors.delivery_at_pa_details??''}</span>
                                </div>
                                <div class="col-4">
                                    <label for="inputAddress2" class="form-label">No Of Containers</label>
                                        <input class=" form-control shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" placeholder="No Of Containers"
                                        name="no_of_containers"
                                        value={addQuote.no_of_containers}
                                        onChange={handleInput}/>
                                    <span className="text-danger"> {formErrors.no_of_containers??''}</span>
                                </div>
                                <div class="col-4">
                                    <label for="inputAddress2" class="form-label">Container Size</label>
                                    <select class=" form-control shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" placeholder="Containers Size"
                                    name="container_size"
                                    value={addQuote.container_size}
                                    onChange={handleInput}>
                                           <option value="">select</option> 
                                           <option value="20 FT">20 FT</option> 
                                            <option value="40 Ft">40 Ft</option>
                                            <option value="40 HC">40 HC</option>
                                            <option value="45 GP">45 GP</option>
                                        </select>
                                    <span className="text-danger"> {formErrors.container_size??''}</span>
                                </div>
                                <div class="col-4">
                                    <label for="inputAddress2" class="form-label">Container Type</label>
                                    <input class="form-control shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text" placeholder="Container Type"
                                    name="container_type"
                                    value={addQuote.container_type}
                                    onChange={handleInput}
                                    />
                                    <span className="text-danger"> {formErrors.container_type??''}</span>
                                </div>
                                <div class="col-4">
                                    <label for="inputAddress2" class="form-label">Temperature</label>
                                    <input class="form-control shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text" placeholder="Temperature"
                                    name="temprature"
                                    value={addQuote.temprature}
                                    onChange={handleInput}
                                    />
                                    <span className="text-danger"> {formErrors.temprature??''}</span>
                                </div>
                                <div class="col-4">
                                    <label for="inputAddress2" class="form-label">Weight per container</label>
                                        <input class="form-control shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        type="number" placeholder="weight per container"
                                        name="container_weight"
                                        value={addQuote.container_weight}
                                        onChange={handleInput}/>
                                    <span className="text-danger"> {formErrors.container_weight??''}</span>
                                </div>
                                <div class="col-4">
                                    <label for="inputAddress2" class="form-label">Commoditiy</label>
                                        <input class="form-control shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        type="text" placeholder="commodity"
                                        name="commodity"
                                        value={addQuote.commodity}
                                        onChange={handleInput}/>
                                    <span className="text-danger"> {formErrors.commodity??''}</span>
                                </div>
                                <div class="col-3">
                                    <label for="inputAddress2" class="form-label">Departure Date (Between)</label><br></br>
                                    <DatePicker
                                        className=" w-full form-control shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Departure Date"
                                        selected={addQuote.departure_date}
                                        dateFormat="MM-dd-Y"
                                        minDate={new Date()}

                                        onChange={(update) => {
                                            setAddQuote({ ...addQuote, ['departure_date']: update });

                                        }}
                                        isClearable={true}
                                        placeholderText="Enter From and To date"
                                    />
                                    <span className="text-danger"> {formErrors.departureDate??''}</span>
                                
                                </div>
                                <div class="col-3">
                                    {/* <label for="inputAddress2" class="form-label">ccc </label> */}
                                    <br></br>
                                    <DatePicker
                                        className="mt-2  w-100 form-control shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Departure Date"
                                        selected={addQuote.departure_date1}
                                        dateFormat="MM-dd-Y"
                                        minDate={new Date()}
                                        onChange={(update) => {
                                        // setDepartureDate1(update);
                                        setAddQuote({ ...addQuote, ['departure_date1']: update });

                                        }}
                                        isClearable={true}
                                        placeholderText="Enter From and To date"
                                    />
                                    <span className="text-danger"> {formErrors.departureDate??''}</span>

                                </div>
                                <div class="col-6">
                                    <label for="inputAddress2" class="form-label">Load Type</label>

                                    <div class="container">
                                        <div class="row">
                                            <div class="col">
                                                <div class="d-inline-block me-2 fs-3">LCL</div>
                                                <div class="form-check form-switch d-inline-block form-switch-xl">
                                                    <input type="checkbox"  name ="load_type" class="form-check-input" id="site_state" style={{cursor: 'pointer'}}
                                                    checked ={addQuote.load_type==='FCL'}
                                                    onChange={(event)=>{ setAddQuote({ ...addQuote, ['load_type']: (event.target.checked)?'FCL':'LCL' });}}
                                                    
                                                    />
                                                    <label for="site_state" class="form-check-label fs-3 ms-3">FCL</label>
                                                </div>
                                                <span className="text-danger"> {formErrors.load_type??''}</span>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="inputPassword4" class="form-label">Comment</label>
                                        <textarea className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        type="text" placeholder="Comment"
                                        name="comment"
                                        value={addQuote.comment}
                                        onChange={handleInput}
                                        />
                                    <span className="text-danger"> {formErrors.comment??''}</span>

                                </div>
                                <div class="col-12">
                                    <button type="submit" class="btn btn-block login_btn mt-5">Add Quote</button>
                                </div>
                            </form>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    </>
  );
};

export default AddQuote;


          