import React, { useState,useEffect,useRef } from "react";
import axios from "axios";

import $ from "jquery";
import DataTables from "datatables.net";
import {arAgingReportAPI} from "../../../../config/endpoints";

import "./style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons';
{/* <FontAwesomeIcon icon="fa-brands fa-nfc-symbol" /> */}
export default function ArAging() {
  

  const [startDate, setStartDate] = useState();
  const [data, setData] = useState([]);
  const [companyId, setcompanyId] = useState([]);
  const handleButtonClick = ( id) => {
    
    // console.log(id);
  };
    useEffect(() => {
      
    axios.post(arAgingReportAPI,{
        withCredentials:true,
        date:startDate
    })
      .then(function (response) {
        setData(response.data)
        // console.log(response.data.totalSecured);
        // console.log(response.data.totalUnsecured);
        // console.log(response.data.balance_due);
      })
      .catch(function (error) {
        console.log(error);
      });
    },[startDate]);

    const DataTable = DataTables(Window, $);
    const tableRef = useRef();

  useEffect(() => {
     new DataTable(tableRef.current, {
      // iDisplayLength: 1000,
      orderCellsTop: true,
      fixedHeader: true,
      initComplete: function () {

        $('.filters').remove();

        var clone = $('.dataTable thead tr')
        .clone(true)
        .addClass('filters');
        clone.children('th').removeClass('sorting');
        clone.children('th').removeClass('sorting_desc');
        clone.children('th').addClass('sorting_disabled');
        clone.children('th').wrapInner('<td />').contents().unwrap();
        clone.parent().contents().unwrap();
        clone.appendTo('.dataTable thead');
        $('#DataTables_Table_0_filter').remove();

        var api = this.api();

        // For each column
        api
            .columns()
            .eq(0)
            .each(function (colIdx) {
            
                // Set the header cell to contain the input element
                var cell = $('.filters td').eq(
                    $(api.column(colIdx).header()).index()
                );
                var title = $(cell).text();
                $(cell).html('<input type="text" class="dt-filter form-controll rounded px-2 border-1" placeholder="' + title + '" style="width: 100%;" />');
                  // console.log(cell);
                // On every keypress in this input
                $(
                    'input',
                    $('.filters td').eq($(api.column(colIdx).header()).index())
                )
                    .off('keyup change')
                    .on('change', function (e) {
                      e.preventDefault();
                        // Get the search value
                        $(this).attr('title', $(this).val());
                        var regexr = '({search})'; //$(this).parents('th').find('select').val();

                        var cursorPosition = this.selectionStart;
                        // Search the column for that value
                        api
                            .column(colIdx)
                            .search(
                                this.value != ''
                                    ? regexr.replace('{search}', '(((' + this.value + ')))')
                                    : '',
                                this.value != '',
                                this.value == ''
                            )
                            .draw();
                    })
                    .on('keyup', function (e) {
                        e.stopPropagation();

                        $(this).trigger('change');
                        // $(this)
                        //     .focus()[0]
                        //     .setSelectionRange(cursorPosition, cursorPosition);
                    });
            })  ;
    },
      // searching: false,
      paging: false,
      order: [[ 0, 'desc' ]],
      data:data.data,
      "destroy" : true,
      columns: [
        { title: "Company",data:function(d){ return `<label class="text-light-primary">${d.company_name}</label>`  },name:'company_name',
       
      },
        { title: "Secured", data:function(d){ return d.credit_limit<1?'-':`<label class="text-dark">$${Number(d.credit_limit).toLocaleString('en-IN')}</label>`},name:'credit_limit' },
        { title: "Unsecured", data:function(d){ return d.un_secured_limit<1?'-':`<label class="text-dark">$${Number(d.un_secured_limit).toLocaleString('en-IN')}</label>`},name:'un_secured_limit' },
        { title: "Usage", data:function(d){ return (d.balance_due-d.credit_limit)<1?`<label class="text-success-dark">$${Number(d.credit_limit-d.balance_due).toLocaleString('en-IN')} <small>Remained </small></label>`:`<label class="text-dark-pink ">$${Number(d.balance_due-d.credit_limit).toLocaleString('en-IN')} <br> <small>Used</small> </label>`},name:'Used' },
        { title: "Balance", data:function(d){ return d.balance_due<1?'-':`<label class="text-dark ">$${Number(d.balance_due).toLocaleString('en-IN')}</label>`},name:'balance_due' },
        { title: "1-30", data:function(d){ return d.oneto30<1?'-':`<label class="text-light-success">$${Number(d.oneto30).toLocaleString('en-IN')}</label>`},name:'oneto30' },
        { title: "30-60", data:function(d){ return d.thirtyto60<1?'-':`<label class="text-warning">$${Number(d.thirtyto60).toLocaleString('en-IN')}</label>`},name:'thirtyto60',orderable: false },
        { title: "60-90", data:function(d){  return d.sixtyto90<1?'-':`<label class="text-dark-warning">$${Number(d.sixtyto90).toLocaleString('en-IN')}</label>`},name:'sixtyto90' },
        { title: "90+", data:function(d){ return d.ninetyplus<1?'-':`<label class="text-dark-pink">$${Number(d.ninetyplus).toLocaleString('en-IN')}</label>`},name:'ninetyplus' },
        { title: "comments",data:function(d){ return `<label class="text-secondary fw-light">${d.comments}</label>`  },name:'comments'}
       ],
    });
  }, [data]);

 
  return (
            <div className="all_pages_body pt-3 mb-100">
            


              <div className='container '>
                <div className="row ">

                  <div className="col-xl-6 col-sm-6 col-12 ">
                    <div className="card bg-light text-dark border-0 shadow-sm p-2">
                      <div className="card-content">
                        <div className="card-body">
                          <div className="media d-flex">
                            <div className="media-body">
                              <h3>AR Aging Report</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-2 col-sm-6 col-12 ">
                    <div className="card bg-light text-dark border-0 shadow-sm">
                      <div className="card-content">
                        <div className="card-body">
                          <div className="media d-flex">
                            <div className="align-self-center">
                              <FontAwesomeIcon icon={faMoneyCheckDollar}  className=" text-success-light fs-2 float-left"/>
                            </div>
                            <div className="media-body text-end w-100">
                              <h6>${Number(data.totalSecured?.toFixed(2)).toLocaleString('en-IN')}</h6>
                              <small className="fw-600">Total Secured</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-2 col-sm-6 col-12">
                    <div className="card bg-light text-dark border-0 shadow-sm">
                      <div className="card-content">
                        <div className="card-body">
                          <div className="media d-flex">
                            <div className="align-self-center">
                            <FontAwesomeIcon icon={faMoneyCheckDollar}  className="  text-warning fs-2 float-left"/>

                            </div>
                            <div className="media-body text-end w-100">
                              <h6>${Number(data.totalUnsecured?.toFixed(2)).toLocaleString('en-IN')}</h6>
                              <small className="fw-600">Total Unsecured</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-2 col-sm-6 col-12">
                    <div className="card bg-light text-dark border-0 shadow-sm">
                      <div className="card-content">
                        <div className="card-body">
                          <div className="media d-flex">
                            <div className="align-self-center">
                              <FontAwesomeIcon icon={faMoneyCheckDollar}  className=" text-dark-pink fs-2 float-left"/>

                            </div>
                            <div className="media-body text-end w-100">
                              <h6>${Number(data.balance_due?.toFixed(2)).toLocaleString('en-IN')}</h6>
                              <small className="fw-600">Balance Due</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='container mt-3 py-3'>
                  <div className="row">
                    <div className="col-12">
                    
                      <div className="card border-0 shadow">
                        <div className="card-body  bg-white rounded ">
                            
                          <table className="" style={{fontSize:'8px !important'}} ref={tableRef}></table>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
  )
}


