import React, { useState,useEffect,useRef } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "./style.css";
import $ from "jquery";
import DataTables from "datatables.net";
import BarsChart from "./barsChart";
import {arCompaniesMarginAPI} from "../../../../config/endpoints";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons';
// import  "../../ar/aging-report/";
{/* <FontAwesomeIcon icon="fa-brands fa-nfc-symbol" /> */}
export default function BusinessReport() {
  

  const [data, setData] = useState([]);
  const date = new Date();
  const [dateRange, setDateRange] = useState([new Date(date.getFullYear(), date.getMonth()-1, 1),new Date(date.getFullYear(), date.getMonth(),0)]);
  
  const [startDate, endDate] = dateRange;

  const businessReport = ( id) => {
    
    // console.log(id);
  };
    useEffect(() => {

    axios.post(arCompaniesMarginAPI,{
        withCredentials:true,
        startDate:startDate,
        endDate:endDate
    })
      .then(function (response) {
        setData(response.data)
        // console.log(response.data);
        // console.log(response.data.totalUnsecured);
        // console.log(response.data.balance_due);
      })

      .catch(function (error) {
        console.log(error);
      });
    },[endDate]);

    const DataTable = DataTables(Window, $);
    const tableRef = useRef();

  useEffect(() => {
     new DataTable(tableRef.current, {
      // iDisplayLength: 1000,
      orderCellsTop: true,
      fixedHeader: true,
      initComplete: function () {

        $('.filters').remove();

        var clone = $('.dataTable thead tr')
        .clone(true)
        .addClass('filters');
        clone.children('th').removeClass('sorting');
        clone.children('th').removeClass('sorting_desc');
        clone.children('th').addClass('sorting_disabled');
        clone.children('th').wrapInner('<td />').contents().unwrap();
        clone.parent().contents().unwrap();
        clone.appendTo('.dataTable thead');
        $('#DataTables_Table_0_filter').remove();

        var api = this.api();

        // For each column
        api
            .columns()
            .eq(0)
            .each(function (colIdx) {
            
                // Set the header cell to contain the input element
                var cell = $('.filters td').eq(
                    $(api.column(colIdx).header()).index()
                );
                var title = $(cell).text();
                $(cell).html('<input type="text" class="dt-filter form-controll rounded border-1 w-75 m-auto" placeholder="' + title + '" style="width: 100%;" />');
                  // console.log(cell);
                // On every keypress in this input
                $(
                    'input',
                    $('.filters td').eq($(api.column(colIdx).header()).index())
                )
                    .off('keyup change')
                    .on('change', function (e) {
                      e.preventDefault();
                        // Get the search value
                        $(this).attr('title', $(this).val());
                        var regexr = '({search})'; //$(this).parents('th').find('select').val();

                        var cursorPosition = this.selectionStart;
                        // Search the column for that value
                        api
                            .column(colIdx)
                            .search(
                                this.value != ''
                                    ? regexr.replace('{search}', '(((' + this.value + ')))')
                                    : '',
                                this.value != '',
                                this.value == ''
                            )
                            .draw();
                    })
                    .on('keyup', function (e) {
                        e.stopPropagation();

                        $(this).trigger('change');
                        // $(this)
                        //     .focus()[0]
                        //     .setSelectionRange(cursorPosition, cursorPosition);
                    });
            })  ;
    },
      // searching: false,
      paging: false,
      order: [[ 3, 'desc' ]],
      data:data.data,
      "destroy" : true,
      columns: [
        { title: "Company Name",data:function(d){return`<label class="text-light-primary">${d.company_name_old}</label>`;},name:'company_name_old'},
        { title: "Company Code", data:function(d){return`<label class="text-dark-info fw-light">${d.code_name}</label>`;},name:'code_name' },
        { title: "Address",data:function(d){return`<label class="text-secondary fw-light ">${d.address}</label>`;},name:'address'},
        { title: "Total Business", data:function(d){ return `<label class="text-dark ">$ ${Number(d.sum).toLocaleString('en-IN')}</label>`; },name:'sum' }
       ],
    });
  }, [data]);

 
  return (
            <div className="all_pages_body pt-3 mb-100">
            
              <div className='container '>
                <div className="row ">

                  <div className="col-12 mt-3">
                    <div className="card bg-light text-dark border-0 shadow-sm p-2">
                      <div className="card-content">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-8">
                              <h3>Total Business In Current Year (Shipper Based)</h3>
                            </div>
                            <div className="col-4">
                              <div className="float-end">
                                <DatePicker 
                                  selectsRange={true}
                                  startDate={startDate}
                                  endDate={endDate}
                                  onChange={(update) => {
                                    console.log(update);
                                    setDateRange(update);
                                  }}
                                  isClearable={true}
                                  placeholderText="Enter From and To date"
                                />
                              </div>
                            </div>
                          </div>
                         

                        </div>

                        <div className="card-body ">
                          <BarsChart labels={data.companies??[]} series={data.vals??[]} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='container mt-3 py-3'>
                  <div className="row">
                    <div className="col-12">
                    
                      <div className="card border-0 shadow">
                        <div className="card-body  bg-white rounded table_align">
                            
                          <table className="text-left" ref={tableRef}></table>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
  )
}


