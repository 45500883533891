import React, { useState,useEffect,useRef } from "react";

import { Link,useParams } from "react-router-dom";
import axios from "axios";

import Skalaton from "../../../dataTables/skalaton";

import { useHookstate } from '@hookstate/core'
import Store from '../../../../store';
import $ from "jquery";
import DataTables from "datatables.net";
import Moment from 'moment';
import {brokerDsrMonthlyAPI} from "../../../../config/endpoints";

import Pie from "./charts/pie";
import "./style.css";
export default function DsrMonthly() {
  
  const gState = useHookstate(Store);
  const [data, setData] = useState<any[]>([]);
  
  const date = gState.date.get();
  useEffect(() => {
    
    setData([]);
      axios.post(brokerDsrMonthlyAPI,{
          withCredentials:true,
          date:date,
      })
        .then(function (response) {
          setData(response.data)
          
        }).catch(function (error) {
          console.log(error);
        });

      },[date]);

  return (
            <div className="all_pages_body mb-100" >
            

              <div className='container pt-5 '>
              
                <div className="card bg-white">
                  <div className="card-body">
                    
                    <div className="row">
                      <div className="col-md-6 text-start"> 
                          <h3 className="main-title pt-1">Monthly Margin Report Manager</h3>
                      </div>
                      <div className="col-md-6 ">
                          <input  type="month" className="shadow-sm px-2 py-1 border-0 float-end" id="filter-date" value={date} onChange={(e) => gState.date.set(Moment(e.target.value).format('YYYY-MM'))} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='container mt-3 py-3'>
                
                  <div className="row">
                    <div className="col-12">
                      <div className="card border-0 shadow">
                        <div className="card-body  bg-white rounded ">
                            <div className="container shadow min-vh-100 py-2">
                                <div className="table-responsive">
                                    <table className="table table0 accordion">
                                        <thead>
                                            <tr>
                                                <th scope="col" >SL</th>
                                                <th scope="col" >Manager</th>
                                                <th scope="col">Custome Total</th>
                                                <th scope="col">Carrier Total</th>
                                                <th scope="col">Margin</th>
                                                <th scope="col">View Team</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        { (data.length<1)?
                                        <Skalaton rows="10" cols="6" />
                                        :data.map(function(q,l){
                                        return (
                                            <tr key={l}>
                                                <td>{l+1}</td>
                                                <td>{q.name}</td>
                                                <td>${q.customer_total.toFixed(2)}</td>
                                                <td>${q.carrier_total.toFixed(2)}</td>
                                                <td>${q.margin.toFixed(2)}</td>
                                                <td>
                                                  <Link  to={{ pathname: `/admin/dsr/monthly/tl/${q.id}` }}>View Team
                                                  </Link>
                                                </td>
                                            </tr>)
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
  )
}


