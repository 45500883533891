import React, { useState,useEffect,useRef } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import Moment from 'moment';
import $ from "jquery";
import LineChart from "./lineChart";
import {useParams} from 'react-router-dom';

import { useHookstate } from '@hookstate/core';

import Store from '../../../store';
import DataTables from "datatables.net";
// import BarsChart from "../ar/companies/barsChart";
import {brokerInrPaymentsDetailAPI} from "../../../config/endpoints";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheckDollar,faCalendarDays,faCommentsDollar,faDollarSign } from '@fortawesome/free-solid-svg-icons';
// import  "../../ar/aging-report/";
{/* <FontAwesomeIcon icon="fa-brands fa-nfc-symbol" /> */}
export default function InrPaymentsDetail() {
  
  const gState = useHookstate(Store);
  
  const date =new Date();
  const {startDate,endDate} = gState.inrPayments.get();

  // const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth()-14, 1));
  // const [endDate, setEndDate] = useState(new Date(date.getFullYear(), date.getMonth()-2, 1));
  const [data, setData] = useState({data:{paid_date:[],final_inr:[]}});
  const [chunkData, setChunk] = useState([]);
  const params = useParams();
  function chunk(arr, chunkSize) {
    if (chunkSize <= 0) throw "Invalid chunk size";
    var R = [];
    for (var i=0,len=arr.length; i<len; i+=chunkSize)
      R.push(arr.slice(i,i+chunkSize));
    return R;
  }
    useEffect(() => {

    axios.post(brokerInrPaymentsDetailAPI,{
        withCredentials:true,
        user_id:params.user_id,
        startDate:startDate,
        endDate:endDate
    })
      .then(function (response) {
        setData(response.data)
        // console.log(response);
        
      })

      .catch(function (error) {
        console.log(error);
      });
    },[endDate]);

  useEffect(() => {
    if(data.data?.final_inr.length>0){
      let chunks = chunk(data.data?.final_inr,(Math.ceil((data.data?.final_inr.length??0)/2)??0));
      let chunksDate = chunk(data.data?.paid_date,(Math.ceil((data.data?.paid_date.length??0)/2)??0));
      let ob = [];
      chunks.forEach((e,k) => {
        var datekey = (chunksDate[k][0])+' to '+(chunksDate[k][chunksDate[k].length-1]);
        const sum = e.reduce((partialSum, a) => partialSum + a, 0);
        ob.push({sum: sum,to:datekey});
      });
      console.log(ob);
      setChunk(ob);
    }
      
  }, [data]);
  const handleChange = ([newStartDate, newEndDate]) => {
    gState.inrPayments.set({startDate:newStartDate,endDate:newEndDate});
    // setStartDate(newStartDate);
    // setEndDate(newEndDate);
  };
  
  const series = [{
    name: "Self",
    data: data.data?.final_inr??[] 
  }];
  if(data.manager?.final_inr){
    series.push({
      name: "Manager",
      data: data.manager?.final_inr??[] 
    })
  }
  if(data.tl?.final_inr){
      series.push({
      name: "TL",
      data: data.tl?.final_inr??[] 
    });
  }
  return (
            <div className="all_pages_body pt-3 mb-100">
            
              <div className='container '>
                <div className="row ">

                  <div className="col-12 mt-3">
                    <div className="card bg-light text-dark border-0 shadow-sm p-2">
                      <div className="card-content">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-7">
                              <h3>Broker INR Payment Detail({data.data?.name})</h3>
                            </div>
                            <div className="col-5">
                              <div className="float-end">
                                <div className=" datepicker_section">
                                  <DatePicker
                                  
                                      selected={startDate}
                                      onChange={handleChange}
                                      selectsRange
                                      startDate={startDate}
                                      endDate={endDate}
                                      dateFormat="MM/yyyy"
                                      showMonthYearPicker
                                    />
                                    <FontAwesomeIcon className="datepicker_icon " icon={faCalendarDays} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='container mt-1 py-1 '>
                  <div className="row">
                            
                    {chunkData.map((e,i)=>{
                      let colors = ["radial-gradient(circle at right bottom, rgb(231 83 202), rgb(172 1 201))","radial-gradient(circle at right bottom, rgb(231 213 82), rgb(201 130 1))"]
                      return(
                      <div className="col-md-4 my-2 " >
                        <div class="card shadow border-0" style={{background:colors[i]}}>
                            <div class="card-body p-2  d-flex justify-content-between">
                              <div>
                              <h5 className="text-white">₹ {Number(e.sum).toLocaleString('en-IN')}</h5>
                              <p className="text-white" >{e.to}</p>
                              </div>
                              <div>
                              {i==0?<FontAwesomeIcon icon={faDollarSign} className="fs-1 py-2 text-white" />:
                              <FontAwesomeIcon icon={faMoneyCheckDollar} className="fs-1 py-2 text-white" />}
                              </div>
                            </div>
                        </div>
                      </div>)
                    })}
                    
                    {/* <div className="col-md-4 my-2 " >
                      <div class="card shadow border-0" style={{background: "radial-gradient(circle at right bottom, rgb(231 213 82), rgb(201 130 1))"}}>
                          <div class="card-body p-2  d-flex justify-content-between">
                            <div>
                            <h5 className="text-white">$ 23,42,322</h5>
                            <p className="text-white" >First quarter</p>
                            </div>
                            <div>
                            <FontAwesomeIcon icon={faMoneyCheckDollar} className="fs-1 py-2 text-white"  />
                            </div>
                          </div>
                      </div>
                    </div> */}
                    
                    <div className="col-md-4 my-2 " >
                      <div class="card shadow border-0" style={{background: "radial-gradient(circle at right bottom, rgb(82 195 231), rgb(1 37 201))"}}>
                          <div class="card-body p-2  d-flex justify-content-between">
                            <div>
                            <h5 className="text-white">₹ {Number(data.data?.total).toLocaleString('en-IN')}</h5>
                            <p className="text-white" >Total</p>
                            </div>
                            <div>
                            <FontAwesomeIcon icon={faCommentsDollar} className="fs-1 py-2 text-white" />
                            </div>
                          </div>
                      </div>
                    </div>
                    {/* <div className="col-md-2 my-3 " >
                      <div class="card shadow border-0" style={{background: "radial-gradient(circle at right bottom, rgb(252 130 21), rgb(206 2 2))"}}>
                          <div class="card-body p-2  d-flex justify-content-between">
                            <div>
                            <h5 className="text-white">$ 23,42,322</h5>
                            <p className="text-white" >First quarter</p>
                            </div>
                            <div>
                            <FontAwesomeIcon icon={faMoneyCheckDollar} className="fs-1 py-2 text-white" />
                            </div>
                          </div>
                      </div>
                    </div>
                    <div className="col-md-2 my-3 " >
                      <div class="card shadow border-0" style={{background: "radial-gradient(circle at right bottom, rgb(83 174 231), rgb(1 199 201))"}}>
                          <div class="card-body p-2  d-flex justify-content-between">
                            <div>
                            <h5 className="text-white">$ 23,42,322</h5>
                            <p className="text-white" >First quarter</p>
                            </div>
                            <div>
                            <FontAwesomeIcon icon={faMoneyCheckDollar} className="fs-1 py-2 text-white" />
                            </div>
                          </div>
                      </div>
                    </div>
                    
                    <div className="col-md-2 my-3 " >
                      <div class="card shadow border-0" style={{background: "radial-gradient(circle at right bottom, rgb(91 233 121), rgb(19 194 0))"}}>
                          <div class="card-body p-2  d-flex justify-content-between">
                            <div>
                            <h5 className="text-white">$ 23,42,322</h5>
                            <p className="text-white" >First quarter</p>
                            </div>
                            <div>
                            <FontAwesomeIcon icon={faMoneyCheckDollar} className="fs-1 py-2 text-white" />
                            </div>
                          </div>
                      </div>
                    </div> */}
                    
                    

                      
                  </div>
              </div>
              <div className='container mt-1 py-1 '>
                  <div className="row">
                            
                    <div className="col-md-12 my-3 " >
                        <div className="card shadow border-0 "  >
                            <div className="card-body rounded p-2 " >
                              <div className=""  >
                                  <LineChart labels={data.data?.paid_date??[]} series={series} height="450px"   />
                              </div>
                            </div>
                        </div>
                    </div>

                      
                  </div>
              </div>
            </div>
  )
}


