import React, { Component } from "react";
import Chart from "react-apexcharts";

class Pie extends Component {
  constructor(props) {
    super(props);
    this.state = {options:{
      chart: {
        height: 280,
        type: "radialBar",
        colors:['#c20000fc','#e91e1ea3']
      },
      
      series: [67,233,23],
      colors: [ '#00B3E6', 
		   '#f76e5f', '#9e63f0', '#73d298',
		  '#338a9b', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
		  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
		  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
		  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
		  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
		  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
		  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
		  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'],
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 15,
            size: "70%"
          },
          
         track: {
              show: true,
              startAngle: undefined,
              endAngle: undefined,
              background: '#d3242487',
              strokeWidth: '97%',
              opacity: 1,
              margin: 5, 
              dropShadow: {
                  enabled: false,
                  top: 0,
                  left: 0,
                  blur: 3,
                  opacity: 0.5
              }
          },
          dataLabels: {
            showOn: "always",
            name: {
              offsetY: -5,
              show: true,
              color: "#000",
              textTransform: "capitalize",
              fontSize: "14px",
            },
            value: {
              offsetY: -0,
              color: "#111",
              fontSize: "12px",
              show: true,
              formatter: function (val) {
                return val+' Loads';
              }
            }
          }
        }
      },
      legend: {
        show: false,
      },
      stroke: {
        lineCap: "round",
      },
      labels: ['jan','feb','mar','apr','may','jun','jul','aug','sep','oct','nov','dec']??this.props?.name
    }};
    }

  render() {
    // function series(p_data){
    //  let data = p_data;
    //  data.forEach(function(item,i){
    //   data[i] = parseInt((item<1?1:item));
    //  });
    //  return data;
    // }
    return (
      <>
      
                <Chart
                options={this.state.options}
                series={this.props.value}
                type="pie"
                height={this.props.height}
                
                // style={{ "width": "150px"}}

                />
      </>
    );
  }
}
 
export default Pie;