import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
// import Navbar from "../../views/common/header";
import BottomMenu from "../bottomMenu";
import Sidebar from "../../views/common/sidebar";
import routesLinks from '../../../routes/adminRoutes';
import TopMenu from "../sidebarMenu";

export default function Admin() {
    // console.log(routesLinks);
  // generate routes ***************************
//   const getActiveNavbar = (routes) => {
//     let activeNavbar = false;
//     for (let i = 0; i < routes.length; i++) {
//       if (
//         window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
//       ) {
//         return routes[i].secondary;
//       }
//     }
//     return activeNavbar;
//   };
// console.log(routesLinks);
  return (
        <>
        <div className="wrapper d-flex align-items-stretch">
            {/* <BottomMenu /> */}
            <TopMenu/>
            <div className="all_pages_body1">
              <Routes>
                {routesLinks.map((prop, key) => {
                    // console.log(prop.path);
                    
                  return (
                    <Route
                      path={`/${prop.path}`}
                      element={prop.component}
                      key={key}
                    />
                  );
                })}
                {/* <Route
                  path="/auth/sign-in"
                  element={<navigate to="/admin/Dashboard" replace />}
                /> */}
              </Routes>
            </div>
            
        </div>
        <section>
        
            
            
        </section>
        </>
           
  );
}