import React, { Component } from "react";
import Chart from "react-apexcharts";

class ColomnNoGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
         options: {
          series: [{
          data: [21, 22, 10]
        }],
          chart: {
          height: 350,
          type: 'bar',
          events: {
            click: function(chart, w, e) {
              // console.log(chart, w, e)
            }
          },
          toolbar: {
            show: false,
          }
        },
        colors: ["#f76e5f","#9e63f0","#73d298","#338a9b"],
        plotOptions: {
          bar: {
            borderRadius: 5,
            // borderRadiusApplication: 'end',
            // borderRadiusWhenStacked: 'last',
            columnWidth: '45%',
            distributed: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          // lines: { 
          //   show: false  //or just here to disable only y axis
          //  },
          categories: this.props.labels,
          labels: {
            
            style: {
              colors: ["#f76e5f","#9e63f0","#73d298","#338a9b"],
              fontSize: '12px'
            }
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false,
              formatter: function (value) {
                return '$' + Number(value).toLocaleString('en-IN');
              }
          }
         
        },
        grid: {
          show: true,
          strokeDashArray: 7,
          xaxis: {
            lines: { 
              show: false  //or just here to disable only y axis
             }
           },
          yaxis: {
            lines: { 
              show: false  //or just here to disable only y axis
             }
           },
        },
        
        }
    };
    
    }

  render() {
    function series(p_data){
     let data = p_data;
     data.forEach(function(item,i){
      data[i] = parseInt((item<1?1:item));
     });
     return data;
    }
    return (
      <>
      
                <Chart
                options={this.state.options}
                series={this.props.series}
                type="bar"
                height="200px"
                // style={{ "marginTop": "-20px"}}

                />
      </>
    );
  }
}
 
export default ColomnNoGrid;