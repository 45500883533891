import React, { Component } from "react";
//import "../../../pages/report/report.css";
import "../css/all_margin_report.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShip } from '@fortawesome/free-solid-svg-icons';
const ShipperPayment =({date,icon,title,iconColor,label,price})=> {

    return (
      <>
      {/* <div className="card pb-2 bg-white shadow-lg">
        <h4 className="text-center p-2">{title}</h4>
         <div className="px-2">
          <hr className="m-0 " />
         </div> */}
         <div className="bg-transparent">
           <label className="bg-white text-dark ps-3 py-1 pe-5 " style={{'borderRadius': '0px 25px 0 0'}} ><b>{title}</b></label>
        <div className="d-flex align-items-center  p-2 box " >
            <div className="flex-shrink-0 p-3 py-4 border-end border-3 ">
                <span className={iconColor}>
                    <FontAwesomeIcon icon={icon} />
                </span>
            </div>
            <div className="flex-grow-1 ms-3">
                <ul className="list-group list-group-flush">
                  {/* <li className="list-group-item p-1"> <b>{label.label1}:</b> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{price.price1.toFixed(2)}</b></span></li>
                  <li className="list-group-item p-1"> <b>{label.label2}:</b> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{price.price2.toFixed(2)}</b></span></li>
                  <li className="list-group-item p-1"> <b>{label.label3}:</b> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{price.price3.toFixed(2)}</b></span></li> */}
                  <li className="list-group-item p-1">
                    <div className="progress " style={{"height": '25px'}}>
                      <div className="position-absolute text-dark pt-1  w-100"> 
                        <b  className="ps-2" >{label.label1}:</b>
                        <b  className="float-end pe-3" >${Number(price.price1.toFixed(2)).toLocaleString('en-IN')}</b>
                      </div>
                      <div className="progress-bar rounded-end" role="progressbar" style={{"width": (price.price1*100/(price.price1+price.price2+price.price3))+'%',"background": "#f5b849"}} aria-valuenow={price.price1.toFixed(2)} aria-valuemin="0" aria-valuemax={price.price1+price.price2+price.price3} ></div>
                    </div>
                  </li>
                  <li className="list-group-item p-1">
                    <div className="progress " style={{"height": '25px'}}>
                      <div className="position-absolute text-dark pt-1  w-100"> 
                        <b  className="ps-2" >{label.label2}:</b>
                        <b  className="float-end pe-3" >${Number(price.price2.toFixed(2)).toLocaleString('en-IN')}</b>
                      </div>
                      <div className="progress-bar rounded-end" role="progressbar" style={{"width": (price.price2*100/(price.price1+price.price2+price.price3))+'%',"background": "#23b7e5"}} aria-valuenow={price.price2.toFixed(2)} aria-valuemin="0" aria-valuemax={price.price1+price.price2+price.price3} ><b className="position-absolute text-dark"></b></div>
                    </div>
                  </li>
                  <li className="list-group-item p-1">
                    <div className="progress " style={{"height": '25px'}}>
                      <div className="position-absolute text-dark pt-1  w-100"> 
                        <b  className="ps-2" >{label.label3}:</b>
                        <b  className="float-end pe-3" >${Number(price.price3.toFixed(2)).toLocaleString('en-IN')}</b>
                      </div>
                      <div className="progress-bar rounded-end text-dark" role="progressbar" style={{"width": (price.price3*100/(price.price1+price.price2+price.price3))+'%',"background": "#845adfcf"}} aria-valuenow={price.price3.toFixed(2)} aria-valuemin="0" aria-valuemax={price.price1+price.price2+price.price3} ><b  className="position-absolute text-dark"></b></div>
                    </div>
                  </li>
                  {/* <li className="list-group-item p-1" >
                    <div class="progress"  style={{"height": '25px'}}>
                      <div className="progress-bar " role="progressbar" style={{"width": (price.price1*100/(price.price1+price.price2+price.price3))+'%',"background": "-webkit-linear-gradient(left, #845adf 0%,#23b7e5 100%)"}} aria-valuenow={price.price1.toFixed(2)} aria-valuemin="0" aria-valuemax={price.price1+price.price2+price.price3} ><b >${price.price1.toFixed(2)}</b></div>
                      <div className="progress-bar" role="progressbar" style={{"width": (price.price2*100/(price.price1+price.price2+price.price3))+'%',"background": "-webkit-linear-gradient(left, #845adf 0%,#23b7e5 100%)"}} aria-valuenow={price.price2.toFixed(2)} aria-valuemin="0" aria-valuemax={price.price1+price.price2+price.price3} ><b>${price.price2.toFixed(2)}</b></div>
                      
                      <div class="progress-bar bg-info" role="progressbar"style={{"width": (price.price3*100/(price.price1+price.price2+price.price3))+'%',"background": "-webkit-linear-gradient(left, #845adf 0%,#23b7e5 100%)"}} aria-valuenow={price.price3.toFixed(2)} aria-valuemin="0" aria-valuemax={price.price1+price.price2+price.price3}>${price.price3.toFixed(2)}</div>
                    </div>
                  </li> */}
                 
                </ul>
            </div>
          </div>
          </div>
        {/* </div> */}
      
      {/* <div className="card all_margin_card ">
        <div className="card-body">
            <div className="app">
                <div className="row">
                    <div className="mixed-chart">
                      <div class="d-flex">
                        <div class="flex-shrink-0">
                          <span className={iconColor}>
                              <FontAwesomeIcon icon={icon} />
                          </span>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p className="text-muted mb-1 box_title">{title}</p>
                          <div className="py-1">
                            <p className="mb-1"><strong>$ {price.price1.toFixed(2)}</strong> <span className="text-muted locked_text_mute">({label.label1})</span></p>
                            <p className="mb-1"><strong> $ {price.price2.toFixed(2)}</strong>  <span className="text-muted locked_text_mute">({label.label2})</span></p>
                            <p className="mb-1"><strong> Total:</strong> $ {price.price3.toFixed(2)} <span className="text-muted locked_text_mute">({label.label3})</span></p>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    </>

    );
}
export default ShipperPayment;
