import React from "react";
import { Link } from "react-router-dom";
import "./dashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBorderAll,
  faFileInvoiceDollar,
  faIndustry,
  faHandHoldingDollar,
  faChartPie,
  faFileCirclePlus,
  faTruckFast,
  faTrailer,
  faQuidditch,
  faShieldCat,
  faFilterCircleDollar,
  faCoins,
  faCircleDollarToSlot,
} from "@fortawesome/free-solid-svg-icons";

const Index = () => {
  return (
    <div className="main-body home-body">
      <div className="ceo-tab-main-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center my-4">
              <h2>Dashboard</h2>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-xl-12 col-sm-6 col-12">
                  <div
                    className="card ceo-tab-info-card ceo_popup_list"
                    id="edit"
                  >
                    <div className="card-content">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-2 py-6 text-center">
                            <Link to="/admin/margin-reports">
                              <span className="ceo-app-box">
                                <FontAwesomeIcon
                                  icon={faFileInvoiceDollar}
                                  className="text-dark-pink mx-1"
                                />
                              </span>
                               <div className="menu_List">Margin Report</div>
                             
                            </Link>
                          </div>

                          <div className="col-2 py-6 text-center">
                            <Link to="/admin/user">
                              <span className="ceo-app-box">
                                <FontAwesomeIcon
                                  icon={faChartPie}
                                  className="text-light-success mx-2"
                                />
                              </span>
                              <div className="menu_List">Employee Chart </div>
                            </Link>
                          </div>
                          <div className="col-2 py-6 text-center">
                          <Link to="/admin/ar-aging">
                              <span className="ceo-app-box">
                                <FontAwesomeIcon
                                  icon={faIndustry}
                                  className="text-dark-warning"
                                />
                              </span>
                              <div className="menu_List">AR Aging Report </div>
                            </Link>
                          </div>
                          <div className="col-2 py-6 text-center">
                            <Link href="reports">
                              <span className="ceo-app-box">
                                <FontAwesomeIcon
                                  icon={faHandHoldingDollar}
                                  className="text-dark-primary"
                                />
                              </span>
                              <div className="menu_List">Incentives </div>
                            </Link>
                          </div>
                          <div className="col-2 py-6 text-center">
                            <span className="ceo-app-box">
                              <FontAwesomeIcon
                                icon={faFileInvoiceDollar}
                                className="text-light-primary"
                              />
                            </span>
                            <div className="menu_List">All Loads</div>
                          </div>
                          <div className="col-2 py-6 text-center">
                            <span className="ceo-app-box">
                              <FontAwesomeIcon
                                icon={faFileCirclePlus}
                                className="text-light-success"
                              />
                            </span>
                            <div className="menu_List">Internal Live Load</div>
                          </div>
                          <div className="col-2 py-6 text-center">
                            <span className="ceo-app-box">
                              <FontAwesomeIcon
                                icon={faTruckFast}
                                className="text-dark-success"
                              />
                            </span>
                            <div className="menu_List">Shippers </div>
                          </div>
                          <div className="col-2 py-6 text-center">
                            <span className="ceo-app-box">
                              <FontAwesomeIcon
                                icon={faTrailer}
                                className="text-light-info"
                              />
                            </span>
                            <div className="menu_List">Aging Reports </div>
                          </div>

                          <div className="col-2 py-6 text-center">
                            <span className="ceo-app-box">
                              <FontAwesomeIcon
                                icon={faQuidditch}
                                className="text-light-green"
                              />
                            </span>
                            <div className="menu_List">Pending Invoice</div>
                          </div>
                          <div className="col-2 py-6 text-center">
                            <span className="ceo-app-box">
                              <FontAwesomeIcon
                                icon={faShieldCat}
                                className="text-dark-pink"
                              />
                            </span>
                            <div className="menu_List">Received Pay</div>
                          </div>
                          <div className="col-2 py-6 text-center">
                            <span className="ceo-app-box">
                              <FontAwesomeIcon
                                icon={faFilterCircleDollar}
                                className="text-dark-warning"
                              />
                            </span>
                            <div className="menu_List">Carrier </div>
                          </div>
                          <div className="col-2 py-6 text-center">
                            <span className="ceo-app-box">
                              <FontAwesomeIcon
                                icon={faCoins}
                                className="text-dark-warning"
                              />
                              <i className="fa-solid fa-coins fa-xl text-light-green"></i>
                            </span>
                            <div className="menu_List">Marketing</div>
                          </div>
                          <div className="col-2 py-6 text-center">
                            <span className="ceo-app-box">
                              <FontAwesomeIcon
                                icon={faCircleDollarToSlot}
                                className="text-dark-orange"
                              />
                            </span>
                            <div className="menu_List">Rate Mate</div>
                          </div>
                          <div className="col-2 py-6 text-center">
                            <span className="ceo-app-box">
                              <FontAwesomeIcon
                                icon={faQuidditch}
                                className="text-dark-orange"
                              />
                            </span>
                            <div className="menu_List">Rate Mate</div>
                          </div>
                          <div className="col-2 py-6 text-center">
                            <span className="ceo-app-box">
                              <FontAwesomeIcon
                                icon={faCircleDollarToSlot}
                                className="text-dark-orange"
                              />
                            </span>
                            <div className="menu_List">Rate Mate</div>
                          </div>
                          <div className="col-2 py-6 text-center">
                            <span className="ceo-app-box">
                              <FontAwesomeIcon
                                icon={faTrailer}
                                className="text-dark-orange"
                              />
                            </span>
                            <div className="menu_List">Rate Mate</div>
                          </div>
                          <div className="col-2 py-6 text-center">
                            <span className="ceo-app-box">
                              <FontAwesomeIcon
                                icon={faFilterCircleDollar}
                                className="text-dark-orange"
                              />
                            </span>
                            <div className="menu_List">Rate Mate</div>
                          </div>
                          <div className="col-2 py-6 text-center">
                            <span className="ceo-app-box">
                              <FontAwesomeIcon
                                icon={faIndustry}
                                className="text-dark-orange"
                              />
                            </span>
                            <div className="menu_List">Rate Mate</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
