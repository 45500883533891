import React, { useState } from "react";
//import "./login.css";


import  { loginAPI } from "../../../config/endpoints";
import "./style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Particle from './particle';
import axios from "axios";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const navigate = useNavigate();


 

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [error, setError] = useState("");

  let name, value;
  const handleInput = (e) => {
    name = e.target.name;
    value = e.target.value;
    

    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (user.email === "" && user.password === "") {
      setEmailValid(true);
      setPasswordValid(true);
    } else if (user.email === "") {
      setEmailValid(true);
      setPasswordValid(false);
    } else if (user.password === "") {
      setPasswordValid(true);
      setEmailValid(false);
    } else {
      setEmailValid(false);
      setPasswordValid(false);

      const formData = new URLSearchParams();
      formData.append("email", user.email);
      formData.append("password", user.password);

      await axios
        .post(loginAPI, formData.toString(), {
          withCredentials:true,
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.status === 200) {
              const navigation = () => {
                navigate(`/admin`);
              };
              setTimeout(navigation, 2000);
              toast.success("Logged in Successfully!", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast.error("something went wrong!", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          } else {
            toast.error("Something Went Wrong!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          return;
        })
        .catch((error) => {
          console.error(error.response);

          console.log("Invalid credencial");

          setError("username and password does not match");

          toast.error("Invalid credencial", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  return (
    <>
    <Particle />
    
    <div className="login_body">
      <img src="./christmas.svg"  className="position-fixed " style={{right: '-89px',top: '-97px',width: '446px'}} />
      <div className="content">
        <div className="container">
          <div className="row mt-5 contant_wrap">
          <div className="col-md-2"></div>
            <div className="col-md-8 content-8">
              <div className="row login_wrap">
                <div className="col-md-6 login_img_section">
                  <div className="img_shadow">
                    <img src="/login.svg" alt="Imsage" className="img-fluid login_img" />
                  </div>
                  
                </div>
                <div className="col-md-6 contents login_bg">
                  <div className="row justify-content-center">
                    <div className="col-md-10 pt-2">
                      <div className="mb-4">
                        <h3 className="mb-3 login_tittle">Log In</h3>
                        <p className="text-danger">{error}</p>
                        <p className="text-white">Welcome on CEO dashboard </p>
                      </div>
                      <form action="#" method="post">
                        {emailValid ? (
                          <div className=" text-danger ">
                            {" "}
                            Plaease enter the email{" "}
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="form-group first">
                          <input
                            type="email"
                            id="email"
                            name="email"
                            value={user.email}
                            onChange={handleInput}
                            className="form-control"
                          />
                        </div>
                        {passwordValid ? (
                          <div className=" text-danger ">
                            {" "}
                            Plaease enter the Password{" "}
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="form-group last mb-4">
                          <input
                            type="text"
                            id="password"
                            name="password"
                            value={user.password}
                            onChange={handleInput}
                            className="form-control"
                          />
                          <ToastContainer />
                        </div>

                        <button
                          onClick={handleSubmit}
                          className="btn btn-block login_btn"
                        >
                          Sign in
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
          
        </div>
      </div>
    </div>
    </>
  );
};



export default Login;
