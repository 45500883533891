import React, { useState,useEffect } from "react";
import axios from "axios";

import { marginReportAPI } from "../../../config/endpoints";

import { Link } from "react-router-dom";

import { useHookstate } from '@hookstate/core'
import Store from '../../../store';

import SingleCard from './includes/singlePriceCard';
import Donut from './includes/donut';
import TriCard from './includes/triPriceCard';


import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays,faSatellite,faBriefcase,faOutdent,faShip,faWalkieTalkie,faRuble,faDolly,faRocket,faCreditCard } from '@fortawesome/free-solid-svg-icons';
{/* <FontAwesomeIcon icon="fa-brands fa-nfc-symbol" /> */}
export default function Index() {
  
  const gState = useHookstate(Store);
  const marginReportData = gState.marginReport.get();

  const [startDate, setStartDate] = useState(marginReportData.date);
  const [data, setData] = useState();


    // useEffect(() => {

    //   marginReportData
      
    // },[startDate]);
    useEffect(() => {
      gState.marginReport.set({date:startDate});
    axios.post(marginReportAPI,{
        withCredentials:true,
        date:startDate
    })
      .then(function (response) {
        setData(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
    },[startDate]);
  return (
    <div className="all_pages_body mb-100">
        <div className='container'>
          <div className="text-center p-4 card my-4">
            <div className="row">
              <div className="col-md-6 text-start"> 
                  <h3 className="main-title">All Margin reports</h3>
              </div>
              <div className="col-md-6 text-end d-flex justify-content-end">
                {/* <span className="text-muted">Select Time period</span> */}
                {/* <div className="position-relative"> */}
                  {/* <FontAwesomeIcon icon={faCalendarDays} className='datepicker_icon mx-2'/>
                  <inp className="search_datepicker" selected={startDate} onChange={(e) => setStartDate(e)} /> */}
                  <input  type="month" className="shadow-sm px-2 py-1 border-0" id="filter-date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />

                {/* </div>  */}
              </div>
            </div>
          </div>
          <div className="card border-0">
              <div className="card-body">
                <div className="row ">
                  <div className='col-md-3 my-3 align-self-center'>
                    <Link  to={{ pathname: `/admin/margin-reports/received-payments/${startDate}` }}>
                      <SingleCard icon={faSatellite} title='Received Payment' iconColor='card_icon icon-light-pink'  price={Number((data?.received_payment??0.00).toFixed(1)).toLocaleString('en-IN')}/>
                    </Link>
                  </div>
                  <div className='col-md-3 my-3 align-self-center'>
                    <Link  to={{ pathname: `/admin/margin-reports/carrier-payments/${startDate}` }}>
                      <SingleCard icon={faOutdent} title='Carrier Payment' iconColor='card_icon icon-light-blue' price={Number((data?.paid_amount??0.00).toFixed(1)).toLocaleString('en-IN')}/>
                    </Link>

                  </div>
                  <div className='col-md-3 my-3 align-self-center'>
                      <SingleCard icon={faBriefcase} title='Gross Profit'  iconColor='card_icon icon-light-warning' price={Number((data?.gross_profit??0.00).toFixed(1)).toLocaleString('en-IN')}/>

                  </div>
                  <div className='col-md-3  align-self-center'>
                    <Donut date={startDate} series={[(data?.received_payment??0.00),(data?.paid_amount??0.00),(data?.gross_profit??0.00)]}/>
                  </div>
                </div>
              </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div>
                  <h4 className="mt-5 mb-1">Locked incentive </h4>
              </div>
              <div className="row my-4">
                <div className='col-md-4 my-3'>
                    <TriCard  data={data} icon={faShip} title='Shipper Payment'  iconColor='card_icon icon-light-blue' label={{label1:'Company Margin',label2:'Pending Margin',label3:'Total',}}   price={{price1:(data?.total_incentive_amount??0.00),price2:(data?.total_pending_amount??0.00),price3:(data?.total_amount_received??0.00)}}  />
                </div>
                <div className='col-md-4 my-3'>
                    <TriCard  data={data} icon={faWalkieTalkie} title='Locked Carrier Payment'  iconColor='card_icon icon-light-pink' label={{label1:'Current Month',label2:'Pending',label3:'Total',}}  price={{price1:(data?.total_incentive_amount_paid??0.00),price2:(data?.total_pending_amount_paid??0.00),price3:(data?.total_amount_paid??0.00)}}  />
                </div>
                <div className='col-md-4 my-3'>
                    <TriCard  data={data} icon={faRuble} title='Net Profit'  iconColor='card_icon icon-light-warning' label={{label1:'Current Month',label2:'Pending',label3:'Total',}} price={{price1:(data?.total_incentive_margin??0.00),price2:(data?.total_pending_margin??0.00),price3:(data?.total_margin??0.00)}}  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div>
                  <h4 className="mt-4">Outcome from locked incentive  </h4>
              </div>
              <div className="row my-4">
                <div className='col-md-4 my-3'>

                     <TriCard  data={data} icon={faDolly} title='Loadpickup Shipper Payment'  iconColor='card_icon icon-light-pink' label={{label1:'Receivable',label2:'Received',label3:'Pending',}}   price={{price1:(data?.outcome_received_payment.invoice_amt??0.00),price2:(data?.outcome_received_payment.amount_paid??0.00),price3:(data?.outcome_received_payment.pending??0.00)}}  />
                </div>
                <div className='col-md-4 my-3'>
                  <TriCard  data={data} icon={faCreditCard} title='Loadpickup Carrier Payment'  iconColor='card_icon icon-light-blue' label={{label1:'Payable',label2:'Paid',label3:'Pending',}}   price={{price1:(data?.outcome_carrier_payment??0.00),price2:(data?.outcome_carrier_payment_made??0.00),price3:(data?.outcome_carrier_payment_pending??0.00)}}  />

                </div>
                <div className='col-md-4 my-3'>
                <TriCard  data={data} icon={faRocket} title='Total Margin'  iconColor='card_icon icon-light-warning' label={{label1:'Current margin',label2:'Pending',label3:'Total',}}   price={{price1:(data?.outcome_current_margin??0.00),price2:(data?.outcome_pending_margin??0.00),price3:(data?.outcome_margin_total??0.00)}}  />

                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}


