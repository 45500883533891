import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
// import Navbar from "../../views/common/header";
import BottomMenuVnaya from "../bottomMenuVnaya";
import Sidebar from "../../views/common/sidebar";
import routesLinks from '../../../routes/vnayaRoutes';

export default function Admin() {
  return (
        <>
          <div className="container-fluid ">
            <div className="row flex-nowrap">
              <div className="col-md-2">
                <div>
                  <BottomMenuVnaya />
                </div>
                
              </div>
              <div className="col-md-10">
                <div className="vanya_top_menus position-relative mt-2 rounded" style={{"boxShadow": "0px 0px 13px 0px #80808038"}} >
                  {/* <div className="vanaya_top_bar"></div> */}
                  <div className="row vanay_top_row_postion p-3 " style={{"box-shadow": "0px 0px 13px 0px #80808038;"}}>
                    <div className="col-6">
                      <div className="mt-3"> </div>
                    </div>
                    <div className="col-3 text-end"></div>
                    <div className="col-3 text-end">
                      <div className="">
                        <input type="email" className="form-control" placeholder="Enter Date" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                      </div>
                    </div>
                  </div>
                </div>
                <section>
                  <Routes>
                    {routesLinks.map((prop, key) => {
                      return (
                        <Route
                          path={`/${prop.path}`}
                          element={prop.component}
                          key={key}
                        />
                      );
                    })}
                  </Routes>
                </section>
              </div>
            </div>
          </div>
           

       
        </>
           
  );
}