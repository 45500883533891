import React from "react";
import ProtectedRoute from "./ProtectedRoute";
import ProtectedRouteVnaya from "./ProtectedRouteVnaya";
import AdminLayout from "./component/layouts/admin/index";
import VnayaLayout from "./component/layouts/vnaya/index";
import AuthLayout from "./component/layouts/auth/index";
// import D3 from "./component/graphs/dthree";
import D3 from "./component/graphs/dthree";
import Quotes from "./component/broker/quotes";
import AddQute from "./component/broker/addQute";
import updateQute from "./component/broker/updateQute";

import "react-toastify/dist/ReactToastify.css";
import { Routes, Route, Navigate,HashRouter  } from "react-router-dom";
function App() { 
//   setTimeout(function(){
//     window.location.reload();
//  }, 60000*30);
  return (
    <Routes>
      <Route path="auth/*" element={<ProtectedRoute Component={AuthLayout} />} />

      <Route path="admin/*" element={<ProtectedRoute Component={AdminLayout} />} />
      <Route path="vnaya/*" element={<ProtectedRouteVnaya Component={VnayaLayout} />} />
      <Route path="add-quote/:token" Component={AddQute} />
      <Route path="update-quote/:quote_id/:token" Component={updateQute} />
      <Route path="quotes/:token" Component={Quotes} />

      <Route path="/" element={<Navigate to="auth/" replace />} />
      
      {/* <Route path="/vnaya" Component={D3} /> */}
      <Route path="/d3" Component={D3} />
    </Routes>
  );
}

export default App;
