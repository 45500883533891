import React from "react";
// Auth Imports
import SignIn from "../component/views/login/login";
import D3 from "../component/graphs/dthree";
import { AiOutlineLogout } from 'react-icons/ai';
// import SignOut from "views/auth/SignOut";
// import Resister from "views/auth/resister";
const routes = [
  {
    name: "Log In",
    layout: "/auth",
    path: "sign-in",
    display: "hidden",
    icon: <AiOutlineLogout className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "d3",
    layout: "/d3",
    path: "d3",
    display: "hidden",
    icon: <AiOutlineLogout className="h-6 w-6" />,
    component: <D3 />,
  }


];
export default routes;