import React, { useState,useEffect,useRef } from "react";

import { Link,useParams,useNavigate  } from "react-router-dom";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";

import Moment from 'moment';
import {apDownloadCsvAPI} from "../../../../config/endpoints";
import "./style.css";

import DatePicker from "react-datepicker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFileCsv} from "@fortawesome/free-solid-svg-icons";
export default function DownloadCsv() {
  
  const [data, setData] = useState([]);

//  const newDate =  Moment(new Date()).format('YYYY-MM');
 const [dateRange, setDateRange] = useState([null, null]);
 const [startDate, endDate] = dateRange;
      useEffect(() => {
          setData([]);
          axios.post(apDownloadCsvAPI,{
              withCredentials:true,
              startDate:startDate,
              endDate:endDate
          })
          .then(async function (response) {
            setData(response.data)
            data.map((i)=>{
              console.log(i.file);
            })
            console.log(data[0].file);
          }).catch(function (error) {
            console.log(error);
          });
      },[dateRange]);
      
  return (
    <>
      <div className="all_pages_body" >
      
        <ToastContainer />

        <div className='container option_sticky pt-1' style={{zIndex:8}}>
        
          <div className="card bg-white border-0">
            <div className="card-body">
              
            <div className="row">
                <div className="col-8">
                  <h3>Carrier Payment CSV files</h3>
                </div>
                <div className="col-4">
                  <div className="float-end">
                    <DatePicker 
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        console.log(update);
                        setDateRange(update);
                      }}
                      isClearable={true}
                      placeholderText="Enter From and To date"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container mt-5 py-0'>
            <div className="row ">
              {data.map((i)=>{
                return ( <div className="col-md-2 mb-3">
                  <div className="card pdf_card_avtar">
                    <div className="d-flex align-items-center">
                      <a href={i.file} >
                      <div className="flex-shrink-0">
                          <div className="">
                            
                            <svg  shape-rendering="geometricPrecision" className="csv_icon" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 424 511.54">
                              <path fill="#fff" fill-rule="nonzero" d="M133.97 242.65l1.98 22.2c-5.54 2.29-12.5 3.44-20.87 3.44-8.37 0-15.09-.88-20.16-2.65-5.06-1.76-9.05-4.53-11.95-8.32-2.91-3.79-4.94-8.24-6.08-13.35-1.15-5.11-1.72-11.4-1.72-18.89s.57-13.81 1.72-18.96c1.14-5.16 3.17-9.63 6.08-13.42 5.63-7.31 15.99-10.96 31.05-10.96 3.35 0 7.29.33 11.82.99 4.54.66 7.91 1.47 10.11 2.44l-3.96 20.22c-5.73-1.23-10.97-1.85-15.72-1.85-4.76 0-8.07.44-9.92 1.32-1.84.88-2.77 2.64-2.77 5.29v34.62c3.43.7 6.91 1.05 10.44 1.05 7.49 0 14.14-1.05 19.95-3.17zm11.9 22.2l3.69-21.8c8.11 2.03 15.4 3.04 21.87 3.04 6.48 0 11.7-.27 15.66-.79v-6.61l-11.89-1.06c-10.75-.97-18.13-3.54-22.13-7.73-4.01-4.18-6.02-10.37-6.02-18.56 0-11.28 2.45-19.03 7.34-23.26s13.19-6.34 24.91-6.34 22.28 1.1 31.71 3.3l-3.3 21.14c-8.19-1.32-14.76-1.98-19.69-1.98-4.93 0-9.12.22-12.56.66v6.48l9.52.92c11.54 1.15 19.51 3.9 23.92 8.26 4.4 4.36 6.6 10.42 6.6 18.17 0 5.55-.74 10.24-2.24 14.07-1.5 3.83-3.28 6.74-5.35 8.72-2.07 1.99-5 3.5-8.79 4.56-3.79 1.06-7.11 1.7-9.98 1.92-2.86.22-6.67.33-11.43.33-11.45 0-22.07-1.15-31.84-3.44zm128.57-81.13h27.88l-20.48 82.59h-38.59l-20.48-82.59h27.88l11.24 52.46h1.19l11.36-52.46z"/>
                              <path fill="#fff" fill-rule="nonzero" d="M86.37 413.44c-11.76 0-11.76-17.89 0-17.89H189.1c-.2 2.95-.31 5.93-.31 8.94s.11 5.99.31 8.95H86.37zM265.81 24.26v29.1c0 65.66 15.31 69.47 69.08 69.47h22.03l-91.11-98.57zm94.33 115.92h-21.48c-61.02 0-90.2-4.09-90.2-86.28V17.35H56.82c-21.7 0-39.47 17.78-39.47 39.47v264.79H219.2c-4.64 5.47-8.83 11.34-12.51 17.54H17.35v89.83c0 21.62 17.85 39.47 39.47 39.47h149.04c3.53 6.12 7.56 11.92 12.02 17.34H56.82C25.63 485.79 0 460.17 0 428.98V56.82C0 25.55 25.55 0 56.82 0h206.33a8.68 8.68 0 016.93 3.45l105.07 113.68c2.19 2.37 2.34 4.66 2.34 7.52v166.86c-5.55-2.98-11.35-5.56-17.35-7.71V140.18z"/>
                              <path fill="#e91e1e" d="M316.95 297.45c59.12 0 107.05 47.93 107.05 107.05 0 59.11-47.93 107.04-107.05 107.04S209.9 463.61 209.9 404.5c0-59.12 47.93-107.05 107.05-107.05z"/>
                              <path fill="#fff" fill-rule="nonzero" d="M337.9 356.54l-3.77 47.75 17.35-6.07c11.47-4.4 23.27 3.72 14.38 13.82-10.82 12.45-27.26 29.55-39.22 40.94-7.43 7.42-11.73 7.49-19.18.06-13.24-13-26.24-27.44-39.18-40.87-9.25-10.06 2.3-18.55 14.28-13.95l17.16 6.01c-1.25-16.28-2.82-31.84-3.77-48.1 0-2.99 2.5-5.39 5.42-5.61 10.31 0 20.84-.24 31.12 0 2.92.22 5.42 2.62 5.42 5.61l-.01.41z"/>
                            </svg>
                          </div>
                      </div>
                      </a>
                      <div className="flex-grow-1 ms-3 bg-white card_body_curve">
                        <div className="card-body pdf_card_spacer">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h5 className="pdf_card_title">Payment CSV</h5>
                              <p className="card-text"><small className="text-muted date_text">
                                <svg id="Layer_1" data-name="Layer 1" className="pdf_icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.89"><path d="M81.61,4.73C81.61,2.12,84.19,0,87.38,0s5.77,2.12,5.77,4.73V25.45c0,2.61-2.58,4.73-5.77,4.73s-5.77-2.12-5.77-4.73V4.73ZM66.11,105.66c-.8,0-.8-10.1,0-10.1H81.9c.8,0,.8,10.1,0,10.1ZM15.85,68.94c-.8,0-.8-10.1,0-10.1H31.64c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H56.77c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H81.9c.8,0,.8,10.1,0,10.1Zm25.14-10.1H107c.8,0,.8,10.1,0,10.1H91.25c-.8,0-.8-10.1,0-10.1ZM15.85,87.3c-.8,0-.8-10.1,0-10.1H31.64c.8,0,.8,10.1,0,10.1ZM41,87.3c-.8,0-.8-10.1,0-10.1H56.77c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H81.9c.8,0,.8,10.1,0,10.1Zm25.14,0c-.8,0-.8-10.1,0-10.1H107c.8,0,.8,10.1,0,10.1Zm-75.4,18.36c-.8,0-.8-10.1,0-10.1H31.64c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H56.77c.8,0,.8,10.1,0,10.1ZM29.61,4.73C29.61,2.12,32.19,0,35.38,0s5.77,2.12,5.77,4.73V25.45c0,2.61-2.58,4.73-5.77,4.73s-5.77-2.12-5.77-4.73V4.73ZM6.4,43.47H116.47v-22a3,3,0,0,0-.86-2.07,2.92,2.92,0,0,0-2.07-.86H103a3.2,3.2,0,0,1,0-6.4h10.55a9.36,9.36,0,0,1,9.33,9.33v92.09a9.36,9.36,0,0,1-9.33,9.33H9.33A9.36,9.36,0,0,1,0,113.55V21.47a9.36,9.36,0,0,1,9.33-9.33H20.6a3.2,3.2,0,1,1,0,6.4H9.33a3,3,0,0,0-2.07.86,2.92,2.92,0,0,0-.86,2.07v22Zm110.08,6.41H6.4v63.67a3,3,0,0,0,.86,2.07,2.92,2.92,0,0,0,2.07.86H113.55a3,3,0,0,0,2.07-.86,2.92,2.92,0,0,0,.86-2.07V49.88ZM50.43,18.54a3.2,3.2,0,0,1,0-6.4H71.92a3.2,3.2,0,1,1,0,6.4Z"/></svg> 
                                {Moment(new Date (i.created_at)).format('MM/DD/YYYY' )}</small>
                              </p>
                            </div>
                            <div>
                              <a href={i.file} >
                              </a>
                            </div>
                          </div>
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>)
              })}

            </div>
        </div>
      </div>
    </>
  )
}


