import React, { Component } from "react";
import Chart from "react-apexcharts";

class Pie extends Component {
  constructor(props) {
    super(props);
    let agentName = this.props?.agent;
    this.state = {
         options: {
          chart: {
            height: 100,
            type: "donut",
            // series: [44, 55, 13, 33],
            // labels: ['Apple', 'Mango', 'Orange', 'Watermelon']
          },
          // series: [100, 500, 300, 200],
          labels: this.props.labels??[],
          innerLabel:'robin',
          dataLabels: {
            enabled: true,
            formatter: function (val,i) {
              return  Number(i.w.config.series[i.seriesIndex]).toLocaleString('en-IN');
            },
            dropShadow: {
              show:true
            }
          },
          legend: {
            show: false,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  fontSize: '12px',
                  name: {
                    show: true,
                    offsetY: 8,
                    formatter: function (w) {
                      // console.log(agentName);
                        return agentName;
                    }
                  },
                  value:{
                    show:false,
                  },
                  total: {
                    show: true,
                  }
                }
              }
            }
          },
          colors: ["#f76e5f","#9e63f0","#73d298","#338a9b"],
        }
      };
    }

  render() {
    function series(p_data){
     let data = p_data;
     data.forEach(function(item,i){
      data[i] = parseInt((item<1?1:item));
     });
     return data;
    }
    return (
      <>
      
                <Chart
                options={this.state.options}
                series={this.props.series??[]}
                type="donut"
                height="150px"
                // style={{ "marginTop": "-20px"}}

                />
      </>
    );
  }
}
 
export default Pie;