import React, { Component } from "react";
import Chart from "react-apexcharts";

class Pie extends Component {
  constructor(props) {
    super(props);
    this.state = {options:{
      chart: {
        height: 280,
        type: "radialBar",
        colors:['#c20000fc']
      },
      
      series: [67],
      colors:['#46d04ccf'],
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 15,
            size: "70%"
          },

         track: {
              show: true,
              startAngle: undefined,
              endAngle: undefined,
              background: '#d3242487',
              strokeWidth: '97%',
              opacity: 1,
              margin: 5, 
              dropShadow: {
                  enabled: false,
                  top: 0,
                  left: 0,
                  blur: 3,
                  opacity: 0.5
              }
          },
          dataLabels: {
            showOn: "always",
            name: {
              offsetY: -5,
              show: true,
              color: "#000",
              textTransform: "capitalize",
              fontSize: "14px",
            },
            value: {
              offsetY: -0,
              color: "#111",
              fontSize: "12px",
              show: true,
              formatter: function (val) {
                return val+' Loads';
              }
            }
          }
        }
      },
    
      stroke: {
        lineCap: "round",
      },
      labels: [this.props?.name]
    }};
    }

  render() {
    // function series(p_data){
    //  let data = p_data;
    //  data.forEach(function(item,i){
    //   data[i] = parseInt((item<1?1:item));
    //  });
    //  return data;
    // }
    return (
      <>
      
                <Chart
                options={this.state.options}
                series={[0]}
                type="radialBar"
                height="150px"
                
                // style={{ "width": "150px"}}

                />
      </>
    );
  }
}
 
export default Pie;