import React, {useEffect,useRef,useState} from 'react';
import "./bottom.css";

import "./backgroundAnimation.css";
import { Link,useLocation,useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { logoutAPI} from "../../config/endpoints";
import axios from "axios";

import {
  faHouse,
  faSackDollar,
  faLaptopFile,
  faFileInvoiceDollar,
  faRightFromBracket,
  faMoneyCheckDollar,
  faMoneyBillTrendUp,
  faChartPie,
  faChevronRight,
  faChevronLeft,
  faChartLine,
  faFileCsv,
  faIndianRupeeSign,
  faCoins,
  faCircleDollarToSlot,
} from "@fortawesome/free-solid-svg-icons";

const TopMenu = () => {
  let ref = useRef();
  
  const navigate = useNavigate();
 const [active, setActive] = useState();
 const location = useLocation();
//  const  getpath = ()=> {
    
//     console.log(location.pathname);
//     return location.pathname;
//   }
  
  const handleOnClick = (e) => {

    var offsetValue = (ref.current.offsetWidth/2)-(e.target.closest('.scroll_container_sub').offsetWidth/2);
    // console.log(e.target.closest('.scroll_container_sub').offsetWidth/2);
    ref.current.scrollTo({
      top: 0,
      left:e.target.closest('.scroll_container_sub').offsetLeft-offsetValue,
      behavior: 'smooth'
    })

}
const logOut = ()=>{
    axios.post(logoutAPI,{
        withCredentials:true,
      }).then(function (response) {
        navigate("/");
      }).catch(function (error) {});
}

  const handleScroll = (e) => {
        // console.log(ref.current.querySelector('.scroll_bg_section'));
        let maincontainer = ref.current.querySelector('.scroll_bg_section').offsetWidth/3;
        // console.log(maincontainer.offsetWidth);
        e.preventDefault();
        let deltaY =  parseInt(e.deltaY);
        if ( deltaY > 0){ 
            ref.current.scrollTo({
                top: 0,
                left:ref.current.scrollLeft + maincontainer,
                behavior: 'smooth'
            })
            
        }
        else {         
            ref.current.scrollTo({
                top: 0,
                left:ref.current.scrollLeft - maincontainer,
                behavior: 'smooth'
            })
        }
    }
  const clickScroll = (to) => {
        let maincontainer = ref.current.querySelector('.scroll_bg_section').offsetWidth/3;
        if ( to == 'L'){ 
            ref.current.scrollTo({
                top: 0,
                left:ref.current.scrollLeft - maincontainer,
                behavior: 'smooth'
            })
            
        }
        else {         
            ref.current.scrollTo({
                top: 0,
                left:ref.current.scrollLeft + maincontainer,
                behavior: 'smooth'
            })
        }
    }
// useEffect(() =>{
//     ref.current.addEventListener("wheel", handleScroll);
// },[])
    return (
        <>
            <nav id="sidebar" className="">
				<div className="p-4 pt-5">
		  		    <h1><a href="index.html" className="logo"><img src='./image.png' style={{width:'80px', height:'80px'}} /></a></h1>
                    <ul className="list-unstyled components mb-5">
                    <li className="active">
                    <Link to="/admin"> <FontAwesomeIcon icon={faHouse} className="text-white mx-1" /> Home</Link>
                    </li>
                    <li>
                        <Link to="ap/carrier-payment"><FontAwesomeIcon icon={faSackDollar} className="text-white mx-1" />Carrier Payment </Link>
                    </li>
                    <li>
                        <Link to="/admin/ar-aging"> <FontAwesomeIcon icon={faMoneyCheckDollar} className="text-white mx-1" /> AR Aging Report </Link>
                    </li>
                    <li>
                        <Link to="/admin/ar/business-report"><FontAwesomeIcon icon={faChartLine} className="text-white mx-1" /> Business Report </Link>
                    </li>
                    <li>
                        <Link to="/admin/broker/margin-report"><FontAwesomeIcon icon={faChartLine} className="text-white mx-1" /> Broker Performance</Link>
                    </li>
                    <li>
                        <Link to="/admin/broker/monthly-performance"><FontAwesomeIcon icon={faChartLine} className="text-white mx-1" /> Broker Monthly Performance</Link>
                    </li>
                    <li>
                        <Link to="/admin/broker/business-report"><FontAwesomeIcon icon={faMoneyBillTrendUp} className="text-white mx-1" /> Broker Business  </Link>
                    </li>
                    <li>
                        <Link to="/admin/broker/inr-payments"> <FontAwesomeIcon icon={faIndianRupeeSign} className="text-white mx-1" /> Broker INR Margin  </Link>
                    </li>
                    <li>
                        <Link to="/admin/margin-reports"><FontAwesomeIcon icon={faFileInvoiceDollar} className="text-white mx-1" /> Margin Report </Link>
                    </li>
                    
                    <li>
                        <Link to="/admin/user"><FontAwesomeIcon icon={faChartPie} className="text-white mx-1" /> Employee Chart </Link>
                    </li>
                    <li>
                    <Link to="/admin/ap/download-csv"><FontAwesomeIcon icon={faFileCsv} className="text-white mx-1" /> Payments History </Link>
                    </li>
                    <li>
                        <Link to="/admin/dsr"><FontAwesomeIcon icon={faLaptopFile} className="text-white mx-1" /> DSR </Link>
                    </li>
                    <li>
                        <Link href="#"> <FontAwesomeIcon icon={faRightFromBracket} className="text-white mx-1" /> Logout  </Link>
                    </li>
                </ul>
	      </div>
    	</nav>
        </>
    );
}

export default TopMenu;
