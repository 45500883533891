import React, { useState,useEffect } from "react";
import {useParams} from 'react-router-dom';
import "./css/receivedPayment.css";
import Table from "../../dataTables/carrierPayments";

export default function CarrierPayments({date}) {
  const params = useParams();
  const [startDate, setStartDate] = useState(params.date);
  // useEffect(() => {

  //   setStartDate(params.date);
  // }, []);
  
  return (
    <>
        <div className="all_pages_body pt-3 mb-100">
            <div className='container'>
                <div className="row">
                  <div className="col-md-6 text-start"> 
                      <h3 className="main-title">Carrier Payment</h3>
                  </div>
                  <div className="col-md-6 text-end d-flex justify-content-end">
                      <input  type="month" className="shadow-sm px-2 py-1 border-0" id="filter-date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                  </div>
                </div>
            </div>
            <div className='container mt-3 py-3'>
                <div className="row">
                    <div className="col-12">
                      <div className="card border-0 shadow">
                        <div className="card-body  bg-white rounded ">
                          <Table startDate={startDate} />
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
