import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUserAPI } from "./config/endpoints";


import './style.css';

import axios from "axios";
const ProtectedRouteVnaya = (props) => {

  const navigate = useNavigate();
  const { Component } = props;
  const [success, setSuccess] = useState(false);
  const authFunction = async () => {
    axios.defaults.withCredentials = true

    await axios
      .post(loginUserAPI).then(() => {
        setTimeout(() => {
          setSuccess(true);
        }, 3000);

      })
      .catch((error) => {

        setSuccess(true);
        navigate("/auth/sign-in", { replace: true });

      });


  };
  // AUTH FUNCTION ENDS...........}
  useEffect(() => {
    authFunction();
  }, []);
  return success ? (
    <>
      <Component />
    </>
  ) : (<>
    <section className="container wellcome-container loading_wrap">
  <h1 className="title text-center">
    <span class="welcom-text">WELLCOME</span>
    <span class="welcom-text" >TO</span>
    <span class="welcom-text" >VNAYA DASHBOARD</span>
  </h1>
  
  {/* <h2 class="title">
    <span>WELLCOME</span>
    <span>TO</span>
    <span>CEO DASHBOARD</span>
    
    
  </h2> */}
    </section>
  </>);
};
export default ProtectedRouteVnaya;
