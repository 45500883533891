import React from "react";
export default function Skalaton({rows,cols}) {
  function skal(rows,cols){
    let htmlrows = [];
    let htmlcols = [];

    for (let col = 0; col <cols; col++) {
      htmlcols.push(<td className="loading"><div className="bar"></div></td>);
      
    }
    for (let row = 0; row <rows; row++) {
      htmlrows.push(<tr>{htmlcols}</tr>);
      
    }
    return htmlrows;
  }
  return (
    <>
    {skal(rows,cols)}
    </>
  )
}
