import React, { useState,useEffect,useRef } from "react";
import axios from "axios";

import $ from "jquery";
import DataTables from "datatables.net";
import {apAgingReportAPI} from "../../../../config/endpoints";

import "./style.css";
export default function ApAging() {
  
  const [data, setData] = useState([]);
  
    useEffect(() => {
      
    axios.post(apAgingReportAPI,{
        withCredentials:true,
    })
      .then(function (response) {
        setData(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
    },[]);

    const DataTable = DataTables(Window, $);
    const tableRef = useRef();

  useEffect(() => {
     new DataTable(tableRef.current, {
      // iDisplayLength: 1000,
      orderCellsTop: true,
      fixedHeader: true,
      initComplete: function () {

        $('.filters').remove();

        var clone = $('.dataTable thead tr')
        .clone(true)
        .addClass('filters');
        clone.children('th').removeClass('sorting');
        clone.children('th').removeClass('sorting_desc');
        clone.children('th').addClass('sorting_disabled');
        clone.children('th').wrapInner('<td />').contents().unwrap();
        clone.parent().contents().unwrap();
        clone.appendTo('.dataTable thead');
        $('#DataTables_Table_0_filter').remove();

        var api = this.api();

        // For each column
        api
            .columns()
            .eq(0)
            .each(function (colIdx) {
            
                // Set the header cell to contain the input element
                var cell = $('.filters td').eq(
                    $(api.column(colIdx).header()).index()
                );
                var title = $(cell).text();
                $(cell).html('<input type="text" class="dt-filter form-controll" placeholder="' + title + '" style="width: 100%;" />');
                  // console.log(cell);
                // On every keypress in this input
                $(
                    'input',
                    $('.filters td').eq($(api.column(colIdx).header()).index())
                )
                    .off('keyup change')
                    .on('change', function (e) {
                      e.preventDefault();
                        // Get the search value
                        $(this).attr('title', $(this).val());
                        var regexr = '({search})'; //$(this).parents('th').find('select').val();

                        var cursorPosition = this.selectionStart;
                        // Search the column for that value
                        api
                            .column(colIdx)
                            .search(
                                this.value != ''
                                    ? regexr.replace('{search}', '(((' + this.value + ')))')
                                    : '',
                                this.value != '',
                                this.value == ''
                            )
                            .draw();
                    })
                    .on('keyup', function (e) {
                        e.stopPropagation();

                        $(this).trigger('change');
                        // $(this)
                        //     .focus()[0]
                        //     .setSelectionRange(cursorPosition, cursorPosition);
                    });
            })  ;
    },
      // searching: false,
      paging: true,
      // pagingType: 'numbers',
      pageLength: 50,
      // sPaginationType: "four_button",
      // sPaginationType: "listbox",
      order: [[ 2, 'asc' ]],
      data:data.data,
      "destroy" : true,
      columns: [
        { title: "Shipment ID",data:function(d){ return d.shipment_id },name:'shipment_id'},
        { title: "Pay To", data:function(q){ return q.payto=="1"?q.f_companyName:(q.payto=="2"?q.c_company_name:(q.company_name!=null?q.company_name:'-')) },name:'payto' },
        { title: "Age days", data:function(d){ return d.DateDiff},name:'DateDiff' },
        { title: "Agent Name", data:function(d){ return d.agent_name<1?'-':d.agent_name},name:'agent_name' },
        { title: "Invoice No", data:function(d){ return d.invoice_num},name:'invoice_num' },
        { title: "Invoice Date", data:function(d){ return d.invoice_date<1?'-':(d.invoice_date)},name:'invoice_date' },
        { title: "Payment Method", data:function(d){ return d.primery_method; },name:'primery_method' },
        // { title: "Payable Amount", data:function(d){ return d.payable_amount<1?'-':d.payable_amount},name:'payable_amount' }
        
       ],
    });
  }, [data]);

 
  return (
            <div className="all_pages_body pt-3 mb-100" >
            


              <div className='container '>
                <div className="row ">

                  <div className="col-xl-12 col-sm-12 col-12 ">
                    <div className="card bg-light text-dark border-0 shadow-sm p-1">
                      <div className="card-content">
                        <div className="card-body">
                          <div className="media d-flex">
                            <div className="media-body">
                              <h3>AP Aging Report</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='container mt-3 py-3'>
                  <div className="row">
                    <div className="col-12">
                    
                      <div className="card border-0 shadow">
                        <div className="card-body  bg-white rounded ">
                            
                          <table ref={tableRef}></table>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
  )
}


