import React, { useState,useEffect } from "react";

import { Link } from "react-router-dom";
import { homeGetEmployeeAPI,homeTodaysMarginAPI,homeMonthlyMarginAPI,homeMonthlyMarginLockedAPI,homePaymentHistoryAPI,homeCashFlowAPI,homeEfsAPI,homeAgingAPI } from "../../../../config/endpoints";
import axios from "axios";

import MarginReport from "./charts/marginReport";
import DonutHalf from "./charts/donutHalf";
import LineChart from "./charts/lineChart";
import Pie from "./charts/pie";
import Colomn from "./charts/colomn";
import ColomnNoGrid from "./charts/colomnNoGrid";
import PaymentTable from "./tables/paymentTable";
import "./home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowTrendDown,faTruckFast,faFileInvoiceDollar,faMoneyBillTransfer,faHandHoldingDollar,faSackDollar,faMoneyCheckDollar,faCreditCard,faMoneyBillTrendUp,faArrowTrendUp} from "@fortawesome/free-solid-svg-icons";
import { color } from "d3";

const Home = () => {
  
  const [paymentHistory, setPaymentHistory] = useState({pie:[]});
  const [data, setData] = useState({pie:[]});
  const [date, setDate] = useState(new Date());
  useEffect(()=>{
    axios.get('https://ops1.vnaya.com/Ops/api/dashboard',{
      // withCredentials:true,
      // month:month,
    }).then(function (response) {
      setData(response.data);
    }).catch(function (error) {});
  },[])

  


  return (
    <>
    <div className="main py-3 home-body" > 
    
    <section >
      <div className="container">
        <div className="row">
          <div className="col-12" >
            <div className="card border-0 shadow-sm mb-3">
              <div className="card-body">
                <input type="month" value={date} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-8" >
            <div className="card border-0 shadow-sm">
              <div className="card-body">
                <h3 className="card-title m-2">User Tracking Module</h3><br />
                <Colomn series={[{name:'Received',data: [ (data.dashboard??0), (data.profile??0), (data.progress??0), (data.assigments??0), (data.billing_central??0),  (data.payment??0),  (data.faq??0)]}]} 
                labels={['Dashboard', 'Profile', 'Progres-Bar',  'Assigments','Billing-Central', 'Payment', 'FAQ']} height={'480'} />
              </div>
            </div>
          </div>

          <div className="col-4" >
              
              {/* <div className="row">
                
                  <div className="col-xl-12 mb-4 col-md-6">
                        <input name="month" type="month" className="w-100 h-100 p-2 rounded form-control date_input" value={month} onChange={(e)=>{setMonth(e.target.value)}} />
                  </div>
              </div> */}
              <div className="row">

                <div className="col-xl-12 mb-4 col-md-6">
                  <div className="card border-0 shadow-sm">
                    <div className="card-body">
                      <div className="" style={{"position": "relative"}}>
                        <div className="">
                          <div className="card-title mb-auto">
                            <h5 className="mb-1 text-nowrap position-absolute "> PlatForm </h5>
                            
                          </div>
                         
                        </div>
                        <div id="generatedLeadsChart" >
                          
                        <Pie height={'148px'} series={[data.windows??0, data.linux??0, data.mac??0]} labels={['MAC','LINUX','WINDOWS']} /> 
                        </div>
                     
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="col-xl-12 mb-4 col-md-6">
                  <div className="card border-0 shadow-sm">
                    <div className="card-body">
                      <div className="" style={{"position": "relative"}}>
                        <div className="">
                          <div className="card-title mb-auto">
                            <h5 className="mb-1 text-nowrap position-absolute "> Browser </h5>
                            
                          </div>
                         
                        </div>
                        <div id="generatedLeadsChart" >
                          
                        <Pie height={'148px'} series={[data.google_chrome??0, data.edge??0, data.netscape??0,data.apple_safari??0,data.internet??0,data.mozilla??0,data.opera??0]} 
                        labels={['Google-Chrome','Edge','Netscape','Apple-Sfari','Internet-Explorer','Mozilla-Firefox','Opera']} /> 
                        </div>
                     
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="col-xl-12 mb-4 col-md-6">
                  <div className="card border-0 shadow-sm">
                    <div className="card-body">
                      <div className="" style={{"position": "relative"}}>
                        <div className="">
                          <div className="card-title mb-auto">
                            <h5 className="mb-1 text-nowrap position-absolute "> Users </h5>
                            
                          </div>
                         
                        </div>
                        <div id="generatedLeadsChart" >
                          
                        <Pie height={'148px'} series={[data.total_users??0, data.pause_users??0, data.total_active_users??0,data.churn_users??0]} labels={['Total-User','Pasue-User','Active-User','Churn-User']} /> 
                        </div>
                     
                      </div>
                    </div>
                  </div>
                </div>
                

              </div>
              

          </div>
              
        </div>


        <div className="row">

          
          
         
          
          <div className="col-xl-6 mb-2 col-md-4">
            <div className="card border-0 shadow-sm">
              <div className="card-body">
                <div className="" style={{"position": "relative"}}>
                  <div className="">
                    <div className="card-title mb-auto">
                      <h5 className="mb-1 text-nowrap position-absolute "> Users </h5>
                      
                    </div>
                    
                  </div>
                  <div id="generatedLeadsChart" >
                  
                    <Pie series={[data.total_session??0,data.total_session_delivery??0, data.total_session_cancel_by_student??0, data.total_session_cancel_by_tutor??0,data.total_session_technical_issue??0,
                      data.total_session_timelap??0,data.total_session_student_not_join??0]} labels={['total Session','Delivery','Canceled By Student',' Canceled By Tutor','Technical Issue','Timelap','Student Not Join']} height={'330px'} /> 
                  </div>
                
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 mb-2 col-md-4">
            <div className="card border-0 shadow-sm">
              <div className="card-body">
                <div className="" style={{"position": "relative"}}>
                  <div className="">
                    <div className="card-title mb-auto">
                      <h5 className="mb-1 text-nowrap position-absolute "> Users </h5>
                      
                    </div>
                    
                  </div>
                  <div id="generatedLeadsChart"  className="pt-3">
                  
    {/* "am_count": [
        7,
        6,
        1,
        8,
        9,
        9,
        8
    ],
    "day_name": [
        "Jan-19-2024",
        "Jan-20-2024",
        "Jan-21-2024",
        "Jan-22-2024",
        "Jan-23-2024",
        "Jan-24-2024",
        "Jan-25-2024"
    ] */}
                    <LineChart series={[{type:'line', name:'Amount',show: false,data: data.am_count??[]}]} labels={data.day_name??[]} />
                    {/* <LineChart series={} labels={data.day_name??['a','b','c','d','e']} /> */}
                  </div>
                
                </div>
              </div>
            </div>
          </div>
          

        </div>

      </div>
    </section>

    </div>
    </>
  );
};

export default Home;


          