import React,{useEffect, useState, useCallback,useMemo} from "react";
import { useHookstate } from '@hookstate/core'
import Store from '../../../store';
import moment from 'moment';
import 'moment-timezone';
import D3 from "../../graphs/dthree";
import "./users.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faMoneyBillTransfer} from "@fortawesome/free-solid-svg-icons";

// faMoneyBillTransfer
function Users() {
  const gState = useHookstate(Store);
  const shipperDetail = gState.d3data.get();
  
  const newDate = new Date();
  newDate.setMonth(newDate.getMonth() - 1);
  const [date,setdate] = useState(moment(newDate).format('YYYY-MM'));
  
  return (
    <>
    <div className="container pt-3 mb-100">
      <div className="row">
        <div className="clo-12">
        <h4 className="text-center">Employee Chart with Raised Invoice Details</h4>
        <input type="month" value={date} onChange={e => setdate(e.target.value)}  className="float-end" />
        </div>
      </div>
      {/* <div className="row">
    
        <div className="col-12 mt-2">  
          <table className="table small">
            <thead>
              <tr>
                <th scope="col">Broker</th>
                <th scope="col">Short Payment</th>
                <th scope="col">Hold Payment</th>
                <th scope="col">Short Received</th>
                <th scope="col">Hold Received</th>
                <th scope="col">Pending Incentive</th>
                <th scope="col">Company Margin</th>
                <th scope="col">Incentive</th>
                <th scope="col">CMT</th>
                <th scope="col">Red Flag</th>
                <th scope="col">Final INR</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{shipperDetail.lockedIncentiveAll?.broker_name}</td>
                <td>{shipperDetail.lockedIncentiveAll?.short_payment}</td>
                <td>{shipperDetail.lockedIncentiveAll?.hold_pay}</td>
                <td>{shipperDetail.lockedIncentiveAll?.short_payment_received}</td>
                <td>{shipperDetail.lockedIncentiveAll?.hold_payment_received}</td> 
                <td>{shipperDetail.lockedIncentiveAll?.pending_incentive}</td> 
                <td>{shipperDetail.lockedIncentiveAll?.company_margin}</td> 
                <td>{shipperDetail.lockedIncentiveAll?.incentive}</td> 
                <td>{shipperDetail.lockedIncentiveAll?.cmt}</td> 
                <td>{shipperDetail.lockedIncentiveAll?.red_flag}</td> 
                <td>{shipperDetail.lockedIncentiveAll?.final_inr}</td> 
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
      <div className="row">
        <div className="col-3">
       
          <div className="d-flex align-items-center  p-2 py-4 mt-5 box user_amount_text" style={{'boxShadow': '#008eff1c 5px 5px, #008eff26 10px 10px, #008eff1f 15px 15px, #008eff24 20px 20px, #008eff21 25px 25px'}} >
          <div className="ribbon ribbon-top-left"><span>Shipper</span></div>
            <div className="flex-shrink-0 p-3 border-end border-3 " >
              <i className="bi bi-cash-coin fs-1 text-success"></i>
            </div>
            <div className="flex-grow-1 ms-3">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item p-1 hover-text"> <b>Total:</b>  <span class="tooltip-text" id="bottom">I'm a tooltip!</span> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{Number((shipperDetail?.shipper?.total ?? 0.00).toFixed(2)).toLocaleString('en-IN')}</b></span></li>
                  <li className="list-group-item p-1 hover-text"> <b>Received:</b> <span class="tooltip-text" id="bottom">I'm a tooltip!</span> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{Number((shipperDetail?.shipper?.received ?? 0.00).toFixed(2)).toLocaleString('en-IN')}</b></span></li>
                  <li className="list-group-item p-1 hover-text"> <b>Pending:</b> <span class="tooltip-text" id="bottom">I'm a tooltip!</span> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{Number((shipperDetail?.shipper?.pending ?? 0.00).toFixed(2)).toLocaleString('en-IN')}</b></span></li>
                </ul>
            </div>
          </div>
          <div style={{'height':'170px'}}></div>

          
          <div className="d-flex align-items-center p-2 py-4 mt-5 box user_amount_text" style={{'boxShadow': '#008eff1c 5px 5px, #008eff26 10px 10px, #008eff1f 15px 15px, #008eff24 20px 20px, #008eff21 25px 25px'}}>
            <div className="ribbon ribbon-top-left"><span>Margin</span></div>
            <div className="flex-shrink-0 p-3 border-end border-3 ">
              <i className="bi bi-cash-coin fs-1 text-warning"></i>
            </div>
            <div className="flex-grow-1 ms-3">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item p-1 hover-text"> <b>Shipper Total:</b> <span class="tooltip-text" id="bottom">I'm a tooltip!</span> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{Number(shipperDetail?.lockedIncentive?.shipper_total?.toFixed(2)).toLocaleString('en-IN')}</b></span></li>
                  <li className="list-group-item p-1 hover-text"> <b>Carrier Total:</b> <span class="tooltip-text" id="bottom">I'm a tooltip!</span> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{Number(shipperDetail?.lockedIncentive?.carrier_total?.toFixed(2)).toLocaleString('en-IN')}</b></span></li>
                  <li className="list-group-item p-1 hover-text"> <b>Margin</b> <span class="tooltip-text" id="bottom">I'm a tooltip!</span> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{Number(shipperDetail?.lockedIncentive?.margin?.toFixed(2)).toLocaleString('en-IN')}</b></span></li>
                </ul>
            </div>
          </div>

          {/* <div className="d-flex align-items-center p-2 py-4 mt-5 box user_amount_text" style={{'boxShadow': '#008eff1c 5px 5px, #008eff26 10px 10px, #008eff1f 15px 15px, #008eff24 20px 20px, #008eff21 25px 25px'}}>
            <div className="ribbon ribbon-top-left"><span>Margin</span></div>
            <div className="flex-shrink-0 p-3 border-end border-3 ">
            <FontAwesomeIcon icon={faMoneyBillTransfer}  className=" fs-1 text-secondary" />

            </div>
            <div className="flex-grow-1 ms-3">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item p-1"> <b>Shipper:</b> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{Number((shipperDetail?.lockedPendingIncentive?.shipper_total??0).toFixed(2)).toLocaleString('en-IN')}</b></span></li>
                  <li className="list-group-item p-1"> <b>Carrier:</b> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{Number((shipperDetail?.lockedPendingIncentive?.carrier_total??0).toFixed(2)).toLocaleString('en-IN')}</b></span></li>
                  <li className="list-group-item p-1"> <b>Margin:</b> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{Number((shipperDetail?.lockedPendingIncentive?.margin??0 ).toFixed(2)).toLocaleString('en-IN')}</b></span></li>
                </ul>
            </div>
          </div> */}

        </div>
        <div className="col-6">  <D3 date={date}  /></div>
        <div className="col-3">
          
          <div className="d-flex align-items-center p-2  py-4  mt-5  box user_amount_text" style={{'boxShadow': '#008eff1c 5px 5px, #008eff26 10px 10px, #008eff1f 15px 15px, #008eff24 20px 20px, #008eff21 25px 25px'}}>
          <div className="ribbon ribbon-top-left"><span>Carrier</span></div>
            <div className="flex-shrink-0 p-3 border-end border-3 ">
              <i className="bi bi-cash-coin fs-1 text-info"></i>
            </div>
            <div className="flex-grow-1 ms-3">
              <ul className="list-group list-group-flush">
                  <li className="list-group-item p-1 hover-text"> <b>Total:</b> <span class="tooltip-text" id="bottom">I'm a tooltip!</span> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{Number((shipperDetail?.carrier?.total_carrier_payment ?? 0.00).toFixed(2)).toLocaleString('en-IN')}</b></span></li>
                  <li className="list-group-item p-1 hover-text"> <b>Paid:</b> <span class="tooltip-text" id="bottom">I'm a tooltip!</span> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{Number((shipperDetail?.carrierReceived?.received ?? 0.00).toFixed(2)).toLocaleString('en-IN')}</b></span></li>
                  <li className="list-group-item p-1 hover-text"> <b>Pending:</b> <span class="tooltip-text" id="bottom">I'm a tooltip!</span> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{Number(((shipperDetail?.carrier?.total_carrier_payment ?? 0)-(shipperDetail?.carrierReceived?.received ?? 0))?.toFixed(2)).toLocaleString('en-IN')}</b></span></li>
                </ul>
            </div>
          </div>

          <div style={{'height':'170px'}}></div>

          
          <div className="d-flex align-items-center p-2  py-4  mt-5 box user_amount_text" style={{'boxShadow': '#008eff1c 5px 5px, #008eff26 10px 10px, #008eff1f 15px 15px, #008eff24 20px 20px, #008eff21 25px 25px'}}>
            <div className="ribbon ribbon-top-left"><span>Pending</span></div>

            <div className="flex-shrink-0 p-3 border-end border-3 ">
              <i className="bi bi-cash-coin fs-1 text-danger"></i>
            </div>
            <div className="flex-grow-1 ms-3">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item p-1 hover-text"> <b>Shipper:</b> <span class="tooltip-text" id="bottom">I'm a tooltip!</span> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{Number((shipperDetail?.lockedPendingIncentive?.shipper_total??0).toFixed(2)).toLocaleString('en-IN')}</b></span></li>
                  <li className="list-group-item p-1 hover-text"> <b>Carrier:</b> <span class="tooltip-text" id="bottom">I'm a tooltip!</span> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{Number((shipperDetail?.lockedPendingIncentive?.carrier_total??0).toFixed(2)).toLocaleString('en-IN')}</b></span></li>
                  <li className="list-group-item p-1 hover-text"> <b>Margin:</b> <span class="tooltip-text" id="bottom">I'm a tooltip!</span> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{Number((shipperDetail?.lockedPendingIncentive?.margin??0 ).toFixed(2)).toLocaleString('en-IN')}</b></span></li>
                </ul>
            </div>
          </div>

          {/* <div className="d-flex align-items-center p-2  py-4  mt-5 box user_amount_text" style={{'boxShadow': '#008eff1c 5px 5px, #008eff26 10px 10px, #008eff1f 15px 15px, #008eff24 20px 20px, #008eff21 25px 25px'}}>
            <div className="ribbon ribbon-top-left"><span>Pending</span></div>

            <div className="flex-shrink-0 p-3 border-end border-3 ">
              <FontAwesomeIcon icon={faMoneyBillTransfer}  className=" fs-1 text-secondary" />
            </div>
            <div className="flex-grow-1 ms-3">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item p-1"> <b>Shipper:</b> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{Number((shipperDetail?.lockedPendingIncentive?.shipper_total??0).toFixed(2)).toLocaleString('en-IN')}</b></span></li>
                  <li className="list-group-item p-1"> <b>Carrier:</b> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{Number((shipperDetail?.lockedPendingIncentive?.carrier_total??0).toFixed(2)).toLocaleString('en-IN')}</b></span></li>
                  <li className="list-group-item p-1"> <b>Margin:</b> <span className="float-end"><i className="bi bi-currency-dollar"></i><b>{Number((shipperDetail?.lockedPendingIncentive?.margin??0 ).toFixed(2)).toLocaleString('en-IN')}</b></span></li>
                </ul>
            </div>
          </div> */}

        </div>
      </div>

     

    </div>
  
    </>
  );
}

export default Users;
