import React, {useEffect,useRef,useState} from 'react';
import "./bottom.css";
import "./backgroundAnimation.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link,useLocation,useNavigate} from 'react-router-dom';

import { logoutAPI} from "../../config/endpoints";
import axios from "axios";

import {
  faHouse,
  faSackDollar,
  faLaptopFile,
  faFileInvoiceDollar,
  faRightFromBracket,
  faMoneyCheckDollar,
  faMoneyBillTrendUp,
  faChartPie,
  faChevronRight,
  faChevronLeft,
  faChartLine,
  faFileCsv,
  faFilterCircleDollar,
  faCoins,
  faCircleDollarToSlot,
} from "@fortawesome/free-solid-svg-icons";

const BottomMenuVnaya = () => {
  let ref = useRef();
  
  const navigate = useNavigate();
 const [active, setActive] = useState();
 const location = useLocation();

  
    return (
<> 
    {/* <div className='vnaya_blur'></div> */}
    <div class="vl_fixed-button">
          
        <Link to="/admin/"><span className='vl_icon_menu'>v</span> <span className='vl_icon_menu_name'>Loads</span></Link>
        </div>
    <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 w-100 vnaya_nav_position vnaya_sidebar mt-0" style={{"boxShadow": "0px 0px 13px 0px #80808038"}}>
        <div className='d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white ' style={{minHeight:"92vh"}}>
        <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none text-start">
        <span className="fs-4 p-3 pb-0"><img src="https://ops1.vnaya.com/en/assets/images/maroon-vnayalogo2.png" alt="" srcset="" width="150px" /></span>
        </a>
        <hr />
        <ul className="nav nav-pills flex-column mb-auto w-100">
            <li className="nav-item">
                <a href="javascript:void(0)" className="nav-link active text-start ps-0" aria-current="page">
                Home
                </a>
            </li>
            <li className="py-1">
                <a href="javascript:void(0)" className="nav-link link-dark text-start ps-0">
                Dashboard
                </a>
            </li>
            <li className="py-1">
                <a href="javascript:void(0)" className="nav-link link-dark text-start ps-0">
                Item One
                </a>
            </li>
            <li className="py-1">
                <a href="javascript:void(0)" className="nav-link link-dark text-start ps-0">
                Item Two
                </a>
            </li>
            <li className="py-1">
                <a href="javascript:void(0)" className="nav-link link-dark text-start ps-0">
                Item Three
                </a>
            </li>
            
           
            </ul>
            <hr />
            {/* <div className="dropdown auth_position">
                <a href="javascript:void(0)" className="d-flex align-items-center link-dark text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" />
                    <strong>mdo</strong>
                </a>
                <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
                    <li><a className="dropdown-item" href="javascript:void(0)">New project...</a></li>
                    <li><a className="dropdown-item" href="javascript:void(0)">Settings</a></li>
                    <li><a className="dropdown-item" href="javascript:void(0)">Profile</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" href="javascript:void(0)">Sign out</a></li>
                </ul>
            </div> */}
        </div>
    </div>
    <div class="background">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        </div>

  </>
    );
}

export default BottomMenuVnaya;
