import { useState, useEffect } from "react";
import "./quotes.css";
import axios from "axios";

import { useParams,Link } from "react-router-dom";
import {getUserQuoteData, deleteQuote} from "../../config/endpoints";

const Quotes = () => {
  
  const params = useParams();
  const token = params.token;
  const [quotesData, setQuotesData] = useState([]);
  const fetchData = async () => {
    
        try{
            await axios
            .post(`${getUserQuoteData}`,{token:token}, {
                withCredentials: true,
            })
            .then((qdata) => {
                const data = qdata.data;
                setQuotesData(data);
            });
        }catch (err) {
            console.log("errorrr ===========",err)
        }
    
  };

  useEffect(() => {
    fetchData();
  }, []);


const updateQuoteStatus = async(id,i)=>{
    if(id){
        try{
            await axios
            .put(`${deleteQuote}${id}`,
            {
                status: '1',
                token: token,
            },
            
            {
                withCredentials: true,
            })
            .then((res) => {
                // alert("Quote Deleted Successfull!")
                fetchData();
                // window.location.reload()
            });
        }catch (err) {
            console.log("errorrr ===========",err)
        }
    }
}

 if(quotesData.length<0){
  return (<>wating.....</>);
 }
 else{

  return (
    
    <>
      <div className="container mx-auto mt-2 py-2 rounded bg-white">
        <div className="row">
          <div className="col-12">
          <Link className="btn btn-primary float-end"
                                    to={'/add-quote/'+token}
                                    >Add Quote</Link> 
          </div>
        </div>
      </div>
      
      <div className="container mx-auto mt-2 py-2 rounded bg-white">
        
        <div className="row">
          <div className="col-12">
      
            <div className=" flex flex-col">
              <div className="">
                    <table className="table-auto" >
                      <thead className="bg-gray-50  dark:bg-gray-800">
                        <tr>
                          <th scope="col" className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400" >
                            Orginating
                          </th>
                          <th scope="col" className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400" >
                            Orginating Details
                          </th>
                          <th scope="col" className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400" >
                            Delivery
                          </th>
                          <th scope="col" className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
                          > Delivery Details
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
                          >Container Type </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
                          >
                            Load Type
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
                          >
                            No Of Container
                          </th>

                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
                          >
                            Container Size
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
                          >
                            Container Weight
                          </th>
                          {/* <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
                          >
                            Temp
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
                          >
                            Commodity
                          </th> */}
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
                          > Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900">
                        {quotesData ? (
                          <>
                            { quotesData.length>0? quotesData.map((data, index) => (
                              <>
                                <tr key={index}>
                                  <td className="whitespace-nowrap px-4 py-4 text-sm text-center">
                                    {data.originating_at_dp} - {data.originating_at_pa}
                                  </td>
                                  <td className="whitespace-nowrap px-4 py-4 text-sm text-center">
                                    {data.originating_at_dp_details} - {data.originating_at_pa_details}
                                  </td>
                                  <td className=" px-4  py-4 text-sm text-grey-700 dark:text-purple-300">
                                    {data.delivery_at_dp} - {data.delivery_at_pa}
                                  </td>
                                  <td className=" px-4  py-4 text-sm text-gray-700 dark:text-teal-300">
                                    {data.delivery_at_dp_details} - {data.delivery_at_pa_details}
                                  </td>
                                  <td className=" px-4  py-4 text-sm text-gray-700 dark:text-cyan-300">
                                    {data.container_type}
                                  </td>

                                  <td className=" px-4  py-4 text-sm text-gray-700 dark:text-lime-300">
                                    {data.load_type}
                                  </td>
                                  <td className=" px-4  py-4 text-sm text-gray-700 dark:text-gray-300">
                                    {data.no_of_containers}
                                  </td>
                                  <td className=" px-4  py-4 text-sm text-gray-700 dark:text-gray-300">
                                    {data.container_size}
                                  </td>
                                  <td className=" px-4  py-4 text-sm text-gray-700 dark:text-gray-300">
                                    {data.container_weight}
                                  </td>
                                  {/* <td className=" px-4  py-4 text-sm text-gray-700 dark:text-gray-300">
                                    {data.temprature}
                                  </td>
                                  <td className=" px-4  py-4 text-sm text-gray-700 dark:text-gray-300">
                                    {data.commodity}
                                  </td> */}
                              
                                  <td className="whitespace-nowrap px-4 py-4 text-sm">
                                    <div className="flex items-center gap-x-6">
                                        <Link                                  
                                            to={`/update-quote/${data.id}/${token}`}
                                            
                                            className="btn btn-primary">
                                           <svg  width="20px" height="20px" viewBox="0 0 122.88 121.51" version="1.1">
                                            <path   fill="#fff" d="M28.66,1.64H58.88L44.46,16.71H28.66a13.52,13.52,0,0,0-9.59,4l0,0a13.52,13.52,0,0,0-4,9.59v76.14H91.21a13.5,13.5,0,0,0,9.59-4l0,0a13.5,13.5,0,0,0,4-9.59V77.3l15.07-15.74V92.85a28.6,28.6,0,0,1-8.41,20.22l0,.05a28.58,28.58,0,0,1-20.2,8.39H11.5a11.47,11.47,0,0,1-8.1-3.37l0,0A11.52,11.52,0,0,1,0,110V30.3A28.58,28.58,0,0,1,8.41,10.09L8.46,10a28.58,28.58,0,0,1,20.2-8.4ZM73,76.47l-29.42,6,4.25-31.31L73,76.47ZM57.13,41.68,96.3.91A2.74,2.74,0,0,1,99.69.38l22.48,21.76a2.39,2.39,0,0,1-.19,3.57L82.28,67,57.13,41.68Z"/>
                                            </svg>
                                        </Link>
                                        
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-4 py-4 text-sm">
                                    <div className="flex items-center gap-x-6">
                                        <button                                  
                                            onClick={() => {if(window.confirm('Are you sure you want to delete this quote ?')){updateQuoteStatus(data.id,index)};}}
                                            
                                            className="btn btn-danger">
                                           <svg  width="20px" height="20px" viewBox="-3 0 32 32" version="1.1">
    
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="#ee4c4c" fill-rule="evenodd" >
                                                    <g id="Icon-Set-Filled"  transform="translate(-261.000000, -205.000000)" fill="#fff">
                                                        <path d="M268,220 C268,219.448 268.448,219 269,219 C269.552,219 270,219.448 270,220 L270,232 C270,232.553 269.552,233 269,233 C268.448,233 268,232.553 268,232 L268,220 L268,220 Z M273,220 C273,219.448 273.448,219 274,219 C274.552,219 275,219.448 275,220 L275,232 C275,232.553 274.552,233 274,233 C273.448,233 273,232.553 273,232 L273,220 L273,220 Z M278,220 C278,219.448 278.448,219 279,219 C279.552,219 280,219.448 280,220 L280,232 C280,232.553 279.552,233 279,233 C278.448,233 278,232.553 278,232 L278,220 L278,220 Z M263,233 C263,235.209 264.791,237 267,237 L281,237 C283.209,237 285,235.209 285,233 L285,217 L263,217 L263,233 L263,233 Z M277,209 L271,209 L271,208 C271,207.447 271.448,207 272,207 L276,207 C276.552,207 277,207.447 277,208 L277,209 L277,209 Z M285,209 L279,209 L279,207 C279,205.896 278.104,205 277,205 L271,205 C269.896,205 269,205.896 269,207 L269,209 L263,209 C261.896,209 261,209.896 261,211 L261,213 C261,214.104 261.895,214.999 262.999,215 L285.002,215 C286.105,214.999 287,214.104 287,213 L287,211 C287,209.896 286.104,209 285,209 L285,209 Z" id="trash" >

                                                </path>
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                        
                                    </div>
                                  </td>
                                  
                                </tr>
                              </>
                            )):<tr><td colSpan={12} className="text-center">No Data Found</td></tr>}
                          </>
                        ) : (
                          <tr><td colSpan={12} className="text-center py-3">Loading Data.........</td></tr>
                        )}
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </section> */}
    </>
  );
 }

};

export default Quotes;
