import React, { useState,useEffect,useRef } from "react";

import { Link,useParams,useNavigate } from "react-router-dom";
import axios from "axios";

import Skalaton from "../../../dataTables/skalaton";


import { ToastContainer, toast } from "react-toastify";
import { useHookstate } from '@hookstate/core'
import Store from '../../../../store';
import Moment from 'moment';
import {apPaymentAPI,apDonePaymentAPI,apGetPaymentAPI,apVlUploadImageAPI,apSetPaymentAPI} from "../../../../config/endpoints";
import "./style.css";
export default function ConfirmPayment() {
  
  const gState = useHookstate(Store);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalTransAmount, setTotalTransAmount] = useState(0);
  const [image, setImage] = useState();
  const [disableBtn, setDisableBtn] = useState(true);
  const [disableMsg, setDisableMsg] = useState('Kindly download file before confirm payment.');
  const [desc, setDesc] = useState();
  const [progressDisplay, setProgressDisplay] = useState('d-none');
  const [imageName, setImageName] = useState();
  const progress = useRef();

  const date = gState.fullDate.get();
  useEffect(() => {
    
    setData([]);
      axios.post(apGetPaymentAPI,{
          withCredentials:true,
      })
        .then(function (response) {
          setData(response.data?.data??[])
          setTotalTransAmount(response.data.totalTransAmount)
          
        }).catch(function (error) {
          console.log(error);
        });

      },[]);
      
      const handleFileUpload = (event) => {
        setProgressDisplay('d-block');

        // get the selected file from the input
        const file = event.target.files[0];
        // create a new FormData object and append the file to it
        const formData = new FormData();
        formData.append("image", file);
        // make a POST request to the File Upload API with the FormData object and Rapid API headers
        axios
          .post(apVlUploadImageAPI, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "x-rapidapi-host": "file-upload8.p.rapidapi.com",
              "x-rapidapi-key": "your-rapidapi-key-here",
            },
            onUploadProgress: (progressEvent) => {
              let width = ((progressEvent.loaded*100)/progressEvent.total);
              progress.current.style.width = width+'%';
              progress.current.innerHTML = width+'%';
            }
        })
          .then((response) => {
            setImageName(response.data);
            setProgressDisplay('d-none');
          })
          .catch((error) => {
            // handle errors
            
            console.log(error);
          });
      }
      const Payment = ()=>{
        setDisableBtn(true);
          axios.post(apDonePaymentAPI,{
              withCredentials:true,
              desc:desc,
              image:imageName
          })
          .then(function (response) {
            console.log(response.data);
              axios.post(apSetPaymentAPI,{
                  withCredentials:true,
                  data:[],
              })
              .then(async function (response) {
                toast.success('Payment Has been Done Successfully', {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setTimeout(function() {
                  setDisableBtn(false);

                  navigate("/admin/ap/carrier-payment");
                }, 2000);
                    
              }).catch(function (error) {
                console.log(error);
              });
          }).catch(function (error) {
            console.log(error);
          });
      }

  return (
            <div className="all_pages_body mb-100" >
            <ToastContainer />

              <div className='container pt-5 '>
              
                <div className="card bg-white">
                  <div className="card-body">
                    
                    <div className="row">
                      <div className="col-md-6 text-start"> 
                          <h3 className="main-title pt-1">Update Carrier Payment</h3>
                      </div>
                      <div className="col-md-6 ">
                          <a href={apPaymentAPI} onClick={()=>{setDisableBtn(false); setDisableMsg('');}} className="btn btn-outline-warning float-end" >Download File</a>
                      </div> 
                    </div>
                  </div>
                </div>
              </div>
              <div className='container mt-3 py-3'>
                
                  <div className="row">
                    <div className="col-12">
                      <div className="card border-0 shadow">
                        <div className="card-body  bg-white rounded ">
                        <form action="javascript:void(0)"> 
                          <div className="row">
                            <div className="col col-md-6">
                                <textarea type="text" rows="5" className="form-control" id="exampleInputEmail1" placeholder="Note"  style={{"border": "3px dashed #dee2e6"}}   onChange={(e)=>{setDesc(e.target.value)}} >
                                {desc}
                              </textarea>
                            </div>
                            <div className="col col-md-6">
                                <div className="  text-center p-3 rounded" style={{"border": "3px dashed #dee2e6"}}>
                                  <h4 className=" fw-bold" for="">Drop File Here</h4>
                                  <small className="fs-5" for="">or</small>
                                  <div className={'progress '+progressDisplay} >
                                    <div ref={progress} className="progress-bar" role="progressbar"   aria-valuenow='25' aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>
                                  <div className="w-75  m-auto">
                                    <input type="file" className="form-control" id="inputGroupFile01" onChange={handleFileUpload} />
                                  </div>
                                </div>

                            </div>
                            <div class="row">
                              <div class="col col-md-12 p-0 pt-3  ">
                                <button  onClick={()=>{if(window.confirm('Are you sure to make these Done?')){Payment()}}} className="btn btn-outline-success px-5 float-end" disabled={disableBtn} >Confirm</button>
                                <span className="text-danger float-end p-2">{disableMsg}</span> 
                              </div>
                            </div>
                          </div>

                        </form>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div className='container mt-1 py-1'>
                
                  <div className="row">
                    <div className="col-12">
                      <div className="card border-0 shadow">
                        <div className="card-body  bg-white rounded ">
                            <div className="container shadow min-vh-100 py-2">
                                <div className="table-responsive">
                                    {/* <table className="table table0 accordion">
                                        <tbody>
                                        { (data.length<1)?
                                        <Skalaton rows="10" cols="8" />
                                        :data.map(function(q,l){
                                        return (

                                            <tr key={l}>
                                               {q.map((item,i) => {
                                                return (
                                                  <>
                                                {l<5?<th key={i} >{item}</th>:<td key={i}>{item}</td>}
                                                  </>
                                                )
                                               }) }  
                                            </tr>
                                            )})}
                                        </tbody>
                                    </table> */}
                                    <h5>Total : <span className="fw-lighter">$</span><b>{Number(totalTransAmount).toLocaleString('en-IN')}</b></h5>
                                    <table className="table table0 accordion text-center">

                                        <tbody className="text-center">
                                          <tr>
                                            <th scope="col" className="text-sm">SL</th>
                                            <th scope="col" className="text-sm">Pro</th>
                                            <th scope="col" className="text-sm">Pay Method</th>
                                            <th scope="col" className="text-sm">Company Name</th>
                                            <th scope="col"className="text-sm" >Broker Name</th>
                                            <th scope="col" className="text-sm">Ages</th>
                                            <th scope="col"className="text-sm">Carrier Rate</th>
                                          </tr>
                                        </tbody>
                                        <tbody>
                                        { (data.length<1)?
                                        <Skalaton rows="10" cols="8" />
                                        :data.map(function(q,l){
                                          let rowColor = (q.payment_mode=="USD" || q.primery_method == "ACH")?"text-light-primary":"text-dark-warning";

                                        return (
                                          <tr key={l}   >
                                                <td>{l+1} </td>
                                                <td width="10%" className="text-black fw-bold "  >{q.shipment_Id}</td>
                                                <td width="10%" className="text-secondary" >{q.primery_method!=null?q.primery_method:'N/A'}</td>
                                                <td width="25%" className="text-light-primary" >{ q.payto=="1"?q.f_companyName:(q.payto=="2"?q.c_company_name:(q.company_name!=null?q.company_name:'N/A'))}</td> 
                                                <td width="10%" className="text-secondary" >{q.first_name!=null?q.first_name:q.brokername}</td>
                                                <td  className="text-secondary" >{q.Age_date}</td>
                                                
                                                <td  className={rowColor}>${q.payment_mode=="USD" || q.primery_method == "ACH"?((Number(q.payable_amount).toLocaleString('en-IN'))+' USD'):((q.payable_amount_cad!=null)?(Number(q.payable_amount_cad).toLocaleString('en-IN')):(Number(q.carrier_total_cad).toLocaleString('en-IN')))+' CAD' }</td>
                                                
                                            </tr>
                                            )})
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
  )
}


