import Home from "../component/vnaya/dashboard/home/home.jsx";
import {MdHome} from 'react-icons/md'

const Router = [
  {
    name: "Home",
    label: "Home",

    layout: "/home",
    path: "/",
    icon: <MdHome className="h-6 w-6" />,
    display: true,
    component: <Home />
  }
  
];

export default Router;
