import React, { Component } from "react";
import Chart from "react-apexcharts";

class TotalCashFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
        options: {
          labels: ['Received', 'Carrier', 'Gross'],
          colors: ['#845adf', '#23b7e5', '#f5b849'],
          chart: {
            type: 'pie',
          },
          legend: {
            show: false
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 250
              },
              legend: {
                position: 'bottom'
              }
            } 
          }]
        },
      };
    }

  render() {
    function series(p_data){
     let data = p_data;
     data.forEach(function(item,i){
      data[i] = parseInt((item<1?1:item));
     });
     return data;
    }
    return (
      <>
      <div className="app">
        <div className="row">
          <div className="card border-0">
            <div className="card-body p-0">
                <Chart
                options={this.state.options}
                series={series(this.props.series)}
                type="pie"
                width="200"
                />
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}
 
export default TotalCashFlow;