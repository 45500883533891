import React, { useState,useEffect,useRef } from "react";
import axios from "axios";

import $ from "jquery";
import DataTables from "datatables.net";
import {arAgingReportAPI} from "../../../../../config/endpoints";

// import "./style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons';
{/* <FontAwesomeIcon icon="fa-brands fa-nfc-symbol" /> */}
export default function PaymentTable({pdata}) {
  

  const [startDate, setStartDate] = useState();
  const [data, setData] = useState([]);
  
    useEffect(() => {
        setData(pdata.data.filter(function(item){if(item.ninetyplus != '' && item.ninetyplus != null ) return item;}))
    },[pdata]);

    const DataTable = DataTables(Window, $);
    const tableRef = useRef();

  useEffect(() => {
     new DataTable(tableRef.current, {
      orderCellsTop: true,
      fixedHeader: true,
      info: false,
      paging: false,
      scrollCollapse: true,
       scrollY: '422px',
      order: [[ 0, 'desc' ]],
      data:data,
      "destroy" : true,
      columns: [
        { title: "Company Name",data:function(d){ return d.company_name },name:'company_name',
       
      },
        { title: "Credit Limit", data:function(d){ return d.credit_limit<1?'-':Number(d.credit_limit).toLocaleString('en-IN')},name:'credit_limit' },
        { title: "Unsecured limit", data:function(d){ return d.un_secured_limit<1?'-':Number(d.un_secured_limit).toLocaleString('en-IN')},name:'un_secured_limit' },
        { title: "Pending Balance", data:function(d){ return d.balance_due<1?'-':Number(d.balance_due).toLocaleString('en-IN')},name:'balance_due' },
        { title: "90+", data:function(d){ return d.ninetyplus<1?'-':Number(d.ninetyplus).toLocaleString('en-IN')},name:'ninetyplus' }
      ]
    });
  }, [data]);

 
  return (
                            
                          <table className="dataTable" ref={tableRef}></table>
                       
  )
}


