import React, { Component } from "react";
import Chart from "react-apexcharts";

class Pie extends Component {
  constructor(props) {
    super(props);
    this.state = {
         options: {
          chart: {
            height: 100,
            type: "pie",
            // series: [44, 55, 13, 33],
            // labels: ['Apple', 'Mango', 'Orange', 'Watermelon']
          },
          // series: [100, 500, 300, 200],
          labels: this.props.labels??[],
          dataLabels: {
            enabled: true,
            formatter: function (val,i) {
              return  Number(i.w.config.series[i.seriesIndex]).toLocaleString('en-IN');
            },
            dropShadow: {
              show:false
            }
          },
          legend: {
            show: false,
          },
          plotOptions: {
              pie: {
                donut: {
                  size: '65%'
                }
              }
          },
          colors: ["#f76e5f","#9e63f0","#73d298","#338a9b"],
        }
      };
    }

  render() {
    // function series(p_data){
    //  let data = p_data;
    //  data.forEach(function(item,i){
    //   data[i] = parseInt((item<1?1:item));
    //  });
    //  return data;
    // }
    return (
      <>
      
                <Chart
                options={this.state.options}
                series={this.props.series??[]}
                type="pie"
                height="200px"
                // style={{ "marginTop": "-20px"}}

                />
      </>
    );
  }
}
 
export default Pie;