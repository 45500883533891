import React, {useEffect,useRef } from "react";
import{receivedPaymentsAPI} from "../../config/endpoints";
import $ from "jquery";
import DataTables from "datatables.net";

import "datatables.net-dt/css/jquery.dataTables.min.css";

export default function ReceivedPayments({startDate}) {
    const DataTable = DataTables(Window, $);
    const tableRef = useRef();

  useEffect(() => {
     new DataTable(tableRef.current, {
      retrieve: true,
      processing: true,
      serverSide: true,
      iDisplayLength: 100,
      columnDefs: [{
        "defaultContent": "-",
        "targets": "_all"
      }],
      ajax: {
        url: receivedPaymentsAPI,
        method:"POST",
        xhrFields: {
          withCredentials: true
       },
        data:function(e){
            e.date = $('#filter-date').val();
          
        
      },
      },
      "destroy" : true,

      columns: [
        { title: "Shipment ID",data:'shipment_id',name:'shipment_id'},
        { title: "Invoice ID", data:'invoice_id',name:'invoice_id' },
        { title: "Company",data:'customer_name',name:'customer_name' },
        { title: "Amount",data:'amount_paid',name:'amount_paid', searchable:false },
        { title: "Received Date",data:'date_received',name:'date_received', searchable:false },
        { title: "Status",data:function(d){ return (d.pay_status==1)?"Paid":''} ,name:'pay_status', searchable:false},
      ],
    }).ajax.reload();
  }, [startDate]);
  

  return (
    <>
      <table ref={tableRef}></table>
    </>
  )
}
