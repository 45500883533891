import React, { useState,useEffect,useRef } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";

import $ from "jquery";
import DataTables from "datatables.net";
// import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css"; // Core DataTable styling
import "datatables.net-buttons-dt/css/buttons.dataTables.css"; // Buttons styling
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.html5.min"; // HTML5 export buttons
import "jszip"; // Required for Excel export functionality
import "datatables.net-buttons/js/buttons.html5.min";
import {brokermonthlyPerformerAPI,brokermonthlyPerformerExportAPI} from "../../../config/endpoints";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';

import  './style.css';
{/* <FontAwesomeIcon icon="fa-brands fa-nfc-symbol" /> */}


export default function MonthlyPerformance() {
  
  const [data, setData] = useState([]);
  const [nonPerformer, setNonPerformer] = useState([]);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState('desc');
  const [range, setRange] = useState("-100000,100000");
  const date = new Date();
  const [dateRange, setDateRange] = useState([new Date(date.getFullYear(), date.getMonth()-10, 1),new Date(date.getFullYear(), date.getMonth(),0)]);
  const [startDate, endDate] = dateRange;

  const businessReport = ( id) => {
    
    // console.log(id);
  };
    useEffect(() => {
      if(startDate && endDate){
        setLoading(true)
    axios.post(brokermonthlyPerformerAPI,{
        withCredentials:true,
        startDate:startDate,
        endDate:endDate,
        order:order,
        range:range
    })
      .then(function (response) {
        setLoading(false)

        // if(response.data.length>0)
        setData(response.data??[])
        // console.log(response.data.length);
      })

      .catch(function (error) {
        setLoading(false)

        console.log(error);
      });
    }
    },[endDate,order,range]);

    const downloadXlxs = () => {
      if (startDate && endDate) {
        // Make the POST request with the required data
        axios.post(brokermonthlyPerformerExportAPI, {
          startDate: startDate,
          endDate: endDate,
          order: order,
          range: range
        }, {
          // Set response type to 'arraybuffer' to handle binary data
          responseType: 'arraybuffer'
        })
        .then((response) => {
          // Create a Blob from the response data
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          
          // Create a link element
          const link = document.createElement('a');
          
          // Create a URL for the Blob and set it as the href
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          
          // Set the download attribute with the file name
          link.download = 'report.xlsx';
          
          // Append the link to the document body, trigger a click event to start the download, and remove the link afterward
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          
          // Optionally, release the URL object
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading the file:", error);
        });
      }
    }

    const DataTable = DataTables(Window, $);
    const tableRef = useRef();

    useEffect(() => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    
      // Base columns
      const columns = [
        { title: "First/Last Name", data: d => `${d.first_name??''} ${d.last_name??''}`, name: 'first_name' },
        { title: "Total Loads", data: d => Number(d.total_loads).toLocaleString('en-IN'), name: 'total_loads' },
        { title: "Total Margin", data: d => Number(d.margin).toLocaleString('en-IN'), name: 'margin' },
        { title: "Daily Average", data: d => (Number(d.margin)/30).toLocaleString('en-IN'), name: 'margin' },
        { title: "Team Members", data: d => `${d.users_under_manager}`, name: 'users_under_manager' },
        { title: "Daily Agent Average", data: d => ((Number(d.margin)/30)/Number(d.users_under_manager) ).toLocaleString('en-IN'), name: 'margin' },
        // { title: "NoN Performing", data: d => d.mm > 0 ? Number(d.mm).toLocaleString('en-IN') : '-', name: 'mm' },
        // { title: "Non performing Average", data: d => `${parseInt((parseInt(d.mm) * 100) / parseInt(d.totalmonths))}%`, name: 'mm' }
      ];
    
      // Initialize DataTable
      const tbl = new DataTable(tableRef.current, {
        // dom: "Bfrtip",
        searching: true,
        paging: false,
        order: [[0, 'desc']],
        data: data,
        destroy: true,
        buttons: [
          {
            extend: "excelHtml5",
            text: "Export as Excel",
            className: "btn btn-success",
            exportOptions: { columns: ":visible, :hidden" },
            title: "Data Export",
            filename: "Data_Export",
          },
        ],
        columns: columns,
      });
    
      // Append buttons to specific location
      $(tbl).DataTable().buttons().container().appendTo("#example_wrapper .col-md-6:eq(0)");
    
    }, [data]);

 
  return (
            <div className="all_pages_body pt-3 mb-100">
            
              <div className='container '>
                <div className="row ">
                <div className="col-12 mt-3">
                  <div className="card bg-light text-dark border-0 shadow-sm p-2">
                    <div className="card-content">
                      <div className="card-body">
                          <div className="row">
                            <div className="col-7">
                              <h3>Broker Margin Report</h3>
                              <button className="btn btn-outline-dark btn-sm" onClick={downloadXlxs}>Download XLXS Export</button>
                            </div>
                            <div className="col-5">
                              <div className="float-end">
                                  {/* <select name="" className="mx-2 selector_input" value={range} onChange={(event)=>{setRange(event.target.value)}}>
                                    <option value={[-100000,100000]}>ALL</option>
                                    <option value={[0,1000]}>Below 1k</option>
                                    <option value={[1000,5000]}>1k-5k</option>
                                    <option value={[5000,10000]}>5k-10k</option>
                                    <option value={[10000,20000]}>10k-20K</option>
                                    <option value={[20000,50000]}>20k-50K</option>
                                    <option value={[50000,100000]}>50k+</option>
                                  </select> */}
                                <div className="date_picker_section">
                                  <FontAwesomeIcon className="date_picker_icon" icon={faCalendarDays} />
                                  <DatePicker
                                      selectsRange={true}
                                      startDate={startDate}
                                      endDate={endDate}
                                      onChange={(update) => {
                                        console.log(update);
                                        setDateRange(update);
                                      }}
                                      isClearable={true}
                                      placeholderText="Enter From and To date"
                                      className="date_selector_input"
                                      dateFormat="MM/dd/yyyy"           // Format to display month and year only
                                      // showMonthYearPicker            // Limits picker to month and year selection only
                                    />
                                </div>
                                
                                
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                 
                 
                </div>
              </div>

              <div className='container mt-3 py-3'>
                  <div className="row">
                    <div className="col-12">
                    
                      <div className="card border-0 shadow">
                        <div className="card-body bg-white rounded table_align">
                          <>
                           <table className="text-left" ref={tableRef} style={{width:'100%'}}></table> 
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
  )
}



