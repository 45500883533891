import React, { useState,useEffect,useRef } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";

import $ from "jquery";
import DataTables from "datatables.net";
import BarsChart from "./barsChart";
import Pie from "../ar/companies/pie";
import {brokerBusinessReportAPI} from "../../../config/endpoints";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';

import  './style.css';
{/* <FontAwesomeIcon icon="fa-brands fa-nfc-symbol" /> */}
export default function BusinessReport() {
  

  const [data, setData] = useState([]);
  const [order, setOrder] = useState('desc');
  const [range, setRange] = useState('');
  const date = new Date();
  const [dateRange, setDateRange] = useState([new Date(date.getFullYear(), date.getMonth()-1, 1),new Date(date.getFullYear(), date.getMonth(),0)]);
  const [startDate, endDate] = dateRange;

  const businessReport = ( id) => {
    
    // console.log(id);
  };
    useEffect(() => {
      if(startDate && endDate){
    axios.post(brokerBusinessReportAPI,{
        withCredentials:true,
        startDate:startDate,
        endDate:endDate,
        order:order,
        range:range
    })
      .then(function (response) {
        setData(response.data)
        // console.log(response.data.balance_due);
      })

      .catch(function (error) {
        console.log(error);
      });
    }
    },[endDate,order,range]);

    const DataTable = DataTables(Window, $);
    const tableRef = useRef();

  useEffect(() => {
     new DataTable(tableRef.current, {
      // iDisplayLength: 1000,
      orderCellsTop: true,
      fixedHeader: true,
      initComplete: function () {

        $('.filters').remove();

        var clone = $('.dataTable thead tr')
        .clone(true)
        .addClass('filters');
        clone.children('th').removeClass('sorting');
        clone.children('th').removeClass('sorting_desc');
        clone.children('th').addClass('sorting_disabled');
        clone.children('th').wrapInner('<td />').contents().unwrap();
        clone.parent().contents().unwrap();
        clone.appendTo('.dataTable thead');
        $('#DataTables_Table_0_filter').remove();

        var api = this.api();

        // For each column
        api
            .columns()
            .eq(0)
            .each(function (colIdx) {
            
                // Set the header cell to contain the input element
                var cell = $('.filters td').eq(
                    $(api.column(colIdx).header()).index()
                );
                var title = $(cell).text();
                $(cell).html('<input type="text" className="dt-filter form-controll rounded border-1 w-75 m-auto" placeholder="' + title + '" style="width: 100%;" />');
                  // console.log(cell);
                // On every keypress in this input
                $(
                    'input',
                    $('.filters td').eq($(api.column(colIdx).header()).index())
                )
                    .off('keyup change')
                    .on('change', function (e) {
                      e.preventDefault();
                        // Get the search value
                        $(this).attr('title', $(this).val());
                        var regexr = '({search})'; //$(this).parents('th').find('select').val();

                        var cursorPosition = this.selectionStart;
                        // Search the column for that value
                        api
                            .column(colIdx)
                            .search(
                                this.value != ''
                                    ? regexr.replace('{search}', '(((' + this.value + ')))')
                                    : '',
                                this.value != '',
                                this.value == ''
                            )
                            .draw();
                    })
                    .on('keyup', function (e) {
                        e.stopPropagation();

                        $(this).trigger('change');
                        // $(this)
                        //     .focus()[0]
                        //     .setSelectionRange(cursorPosition, cursorPosition);
                    });
            })  ;
    },
      // searching: false,
      paging: false,
      order: [[ 3, 'desc' ]],
      data:data.data,
      "destroy" : true,
      columns: [
        { title: "First Name",data:function(d){return`<label className="text-light-primary">${d.first_name}</label>`;},name:'first_name'},
        { title: "Last Name", data:function(d){return`<label className="text-light-primary">${d.last_name}</label>`;},name:'last_name' },
        { title: "Email",data:function(d){return`<label className="text-dark-info">${d.email}</label>`;},name:'email'},
        { title: "Total Business", data:function(d){ return  `<label className="text-dark">$${Number(d.customer_total).toLocaleString('en-IN')}</label>`; },name:'sum' }
       ],
    });
  }, [data]);

 
  return (
            <div className="all_pages_body pt-3 mb-100">
            
              <div className='container '>
                <div className="row ">
                <div className="col-12 mt-3">
                  <div className="card bg-light text-dark border-0 shadow-sm p-2">
                    <div className="card-content">
                      <div className="card-body">
                          <div className="row">
                            <div className="col-7">
                              <h3>All Non performing Brokers list</h3>
                            </div>
                            <div className="col-5">
                              <div className="float-end">
                                <select name="" className="mx-2 selector_input" value={range} onChange={(event)=>{setRange(event.target.value)}}>
                                  <option value="">ALL</option>
                                  <option value="0-1000">Below 1k</option>
                                  <option value="1000-5000">1k-5k</option>
                                  <option value="5000-10000">5k-10k</option>
                                  <option value="10000-20000">10k-20K</option>
                                  <option value="20000-50000">20k-50K</option>
                                  <option value="50000-100000">50k+</option>
                                </select>
                                <select name="" className="mx-2 selector_input" value={order} onChange={(event)=>{setOrder(event.target.value)}}>
                                  <option value="desc">DESC</option>
                                  <option value="asc">ASC</option>
                                </select>
                                <div className="date_picker_section">
                                  <FontAwesomeIcon className="date_picker_icon" icon={faCalendarDays} />
                                  <DatePicker 
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                      console.log(update);
                                      setDateRange(update);
                                    }}
                                    isClearable={true}
                                    placeholderText="Enter From and To date" className="date_selector_input"
                                  />
                                </div>
                                
                                
                              </div>
                            </div>
                          </div>
                        

                        </div>

                        <div className="card-body">
                        {/* <h3 className="business_broker_box_title"><span>All Non performing Brokers list</span></h3> */}
                          <div className="row">
                            {data.data11?.map((event,i)=>{
                              return (<>
                              {event.first_name=='Nitish'?
                                <div className="col-2 ">
                                <div className="card business_broker_card my-2"  style={{borderRadius:'0px'}}>
                                  <div className="card-content">
                                    <div className="card-body p-1">
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                        <FontAwesomeIcon className="business_load_card_icon text-danger align-self-center mr-3" icon={faChartPie} />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                          <h5 className="business_load_title">{event.first_name}</h5>
                                          <p className="mb-0 mt-1 text-danger"> <span className="business_broker_loads">0  Loads</span></p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>:''}
                              </>)
                            })}
                            {data.data11?.map((event,i)=>{
                              return (<>
                              {/* <Pie name={event.first_name} value={0} key={i} /> */}

                              {event.first_name!='Nitish'? <div className="col-2 ">
                                <div className="card business_broker_card my-2"  style={{borderRadius:'0px'}}>
                                  <div className="card-content">
                                    <div className="card-body p-1">
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                        <FontAwesomeIcon className="business_load_card_icon text-danger align-self-center mr-3" icon={faChartPie} />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                          <h5 className="business_load_title">{event.first_name}</h5>
                                          <p className="mb-0 mt-1 text-danger"> <span className="business_broker_loads">0  Loads</span></p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>:''}
                              </>)
                            })}
                          {data.data11?.length<1?<h1 className="text-center text-danger">No Data Found</h1>:''}
                          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="card bg-light text-dark border-0 shadow-sm p-2">
                      <div className="card-content">
                        {/* <div className="card-body">
                          <div className="row">
                            <div className="col-7">
                              <h3>Total Business In Current Year (Shipper Based)</h3>
                            </div>
                            <div className="col-5">
                              <div className="float-end">
                                <select name="" className="mx-2 selector_input" value={range} onChange={(event)=>{setRange(event.target.value)}}>
                                  <option value="">ALL</option>
                                  <option value="0-1000">Below 1k</option>
                                  <option value="1000-5000">1k-5k</option>
                                  <option value="5000-10000">5k-10k</option>
                                  <option value="10000-20000">10k-20K</option>
                                  <option value="20000-50000">20k-50K</option>
                                  <option value="50000-100000">50k+</option>
                                </select>
                                <select name="" className="mx-2 selector_input" value={order} onChange={(event)=>{setOrder(event.target.value)}}>
                                  <option value="desc">DESC</option>
                                  <option value="asc">ASC</option>
                                </select>
                                <div className="date_picker_section">
                                  <FontAwesomeIcon className="date_picker_icon" icon={faCalendarDays} />
                                  <DatePicker 
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                      console.log(update);
                                      setDateRange(update);
                                    }}
                                    isClearable={true}
                                    placeholderText="Enter From and To date" className="date_selector_input"
                                  />
                                </div>
                                
                                
                              </div>
                            </div>
                          </div>
                         

                        </div> */}
                        
                        <div className="card-body">
                          <BarsChart labels={data.companies} series={[{
              name: "Total Business",
                data: data.vals??[] 
            },{
              name: 'Total Margin',
              data: data.margin??[]
            }]} />
                        </div>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>

              <div className='container mt-3 py-3'>
                  <div className="row">
                    <div className="col-12">
                    
                      <div className="card border-0 shadow">
                        <div className="card-body bg-white rounded table_align">
                          <table className="text-left" ref={tableRef}></table>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
  )
}


