import { hookstate } from '@hookstate/core'

import Moment from 'moment';
const date = new Date();
date.setMonth(date.getMonth() - 1);
const Store = hookstate({
  d3data: '',
  marginReport: {date:new Date()},
  inrPayments: {startDate:new Date(date.getFullYear(), date.getMonth()-12, 1),endDate:new Date(date.getFullYear(), date.getMonth()-1, 1)},
  date:  Moment(date).format('YYYY-MM'),
  fullDate:  Moment(date).format('YYYY-MM-DD')
})

export default Store