import React, { useState,useEffect,useRef } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import {Link} from 'react-router-dom';
import "./style.css";
import Moment from 'moment';
import $ from "jquery";
import Pie from "./pie";

import { useHookstate } from '@hookstate/core';

import Store from '../../../store';
import DataTables from "datatables.net";
import BarsChart from "../ar/companies/barsChart";
import {brokerInrPaymentsAPI} from "../../../config/endpoints";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser,faCalendarDays } from '@fortawesome/free-solid-svg-icons';
// import  "../../ar/aging-report/";
{/* <FontAwesomeIcon icon="fa-brands fa-nfc-symbol" /> */}
export default function InrPayments() {
  
  
  
  const gState = useHookstate(Store);
  
  const date =new Date();
  const {startDate,endDate} = gState.inrPayments.get();
  

  const [data, setData] = useState({data:[],managers:[],tls:[]});
  // const pervDate =;
  // const [startDate, setStartDate] = useState(marginReportData.start_date);
  // const [endDate, setEndDate] = useState(marginReportData.end_date);

  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };
  const businessReport = ( id) => {
    
    // console.log(id);
  };
    useEffect(() => {
      // gState.inrPayments.set({startDate:new Date(date.getFullYear(), date.getMonth()-2, 1),endDate:new Date(date.getFullYear(), date.getMonth()-2, 1)});

    },[]);
    useEffect(() => {

    axios.post(brokerInrPaymentsAPI,{
        withCredentials:true,
        startDate:startDate,
        endDate:endDate
    })
      .then(function (response) {
        setData(response.data)
        console.log(response);
      })

      .catch(function (error) {
        console.log(error);
      });
    },[endDate]);

    // const DataTable = DataTables(Window, $);
    // const tableRef = useRef();
    // const tableRef1 = useRef();
    // const tableRef2 = useRef();

  useEffect(() => {
    //  new DataTable(tableRef.current, {
    //   // iDisplayLength: 1000,
    //   orderCellsTop: true,
    //   fixedHeader: true,
    //   initComplete: function () {

    //     $('.datatable0 .filters').remove();

    //     var clone = $('.datatable0  thead tr')
    //     .clone(true)
    //     .addClass('filters');
    //     clone.children('th').removeClass('sorting');
    //     clone.children('th').removeClass('sorting_desc');
    //     clone.children('th').addClass('sorting_disabled');
    //     clone.children('th').wrapInner('<td />').contents().unwrap();
    //     clone.parent().contents().unwrap();
    //     clone.appendTo('.datatable0  thead');
    //     $('.datatable0 #DataTables_Table_0_filter').remove();

    //     var api = this.api();

    //     // For each column
    //     api
    //         .columns()
    //         .eq(0)
    //         .each(function (colIdx) {
            
    //             // Set the header cell to contain the input element
    //             var cell = $('.datatable0 .filters td').eq(
    //                 $(api.column(colIdx).header()).index()
    //             );
    //             var title = $(cell).text();
    //             $(cell).html('<input type="text" class="dt-filter form-controll rounded border-1 w-75 m-auto" placeholder="' + title + '" style="width: 100%;" />');
    //               // console.log(cell);
    //             // On every keypress in this input
    //             $(
    //                 'input',
    //                 $('.datatable0 .filters td').eq($(api.column(colIdx).header()).index())
    //             )
    //                 .off('keyup change')
    //                 .on('change', function (e) {
    //                   e.preventDefault();
    //                     // Get the search value
    //                     $(this).attr('title', $(this).val());
    //                     var regexr = '({search})'; //$(this).parents('th').find('select').val();

    //                     var cursorPosition = this.selectionStart;
    //                     // Search the column for that value
    //                     api
    //                         .column(colIdx)
    //                         .search(
    //                             this.value != ''
    //                                 ? regexr.replace('{search}', '(((' + this.value + ')))')
    //                                 : '',
    //                             this.value != '',
    //                             this.value == ''
    //                         )
    //                         .draw();
    //                 })
    //                 .on('keyup', function (e) {
    //                     e.stopPropagation();

    //                     $(this).trigger('change');
    //                     // $(this)
    //                     //     .focus()[0]
    //                     //     .setSelectionRange(cursorPosition, cursorPosition);
    //                 });
    //         })  ;
    // },
    //   // searching: false,
    //   paging: false,
    //   order: [[ 0, 'desc' ]],
    //   data:data.data,
    //   "destroy" : true,
    //   columns: [
    //     { title: "Broker Name",data:function(d){return`<label class="text-light-primary">${d.name}</label>`;},name:'name'},
    //     { title: "Email", data:function(d){return`<label class="text-dark-info fw-light">${d.email}</label>`;},name:'email' },
    //     { title: "Final INR",data:function(d){return`<label class="text-secondary fw-light ">${Number(d.final_inr).toLocaleString('en-IN')}</label>`;},name:'final_inr'},
    //     // { title: "Total Business", data:function(d){ return `<label class="text-dark ">$ ${Number(d.sum).toLocaleString('en-IN')}</label>`; },name:'sum' }
    //    ],
    // });
    //  new DataTable(tableRef1.current, {
    //   // iDisplayLength: 1000,
    //   orderCellsTop: true,
    //   fixedHeader: true,
    //   // searching: false,
    //   paging: false,
    //   order: [[ 0, 'desc' ]],
    //   data:data.managers,
    //   "destroy" : true,
    //   columns: [
    //     { title: "Manager Name",data:function(d){return`<label class="text-light-primary">${d.name}</label>`;},name:'name'},
    //     { title: "Email", data:function(d){return`<label class="text-dark-info fw-light">${d.email}</label>`;},name:'email' },
    //     { title: "Final INR",data:function(d){return`<label class="text-secondary fw-light ">${Number(d.final_inr).toLocaleString('en-IN')}</label>`;},name:'final_inr'},
    //     // { title: "Total Business", data:function(d){ return `<label class="text-dark ">$ ${Number(d.sum).toLocaleString('en-IN')}</label>`; },name:'sum' }
    //    ],
    // });
    //  new DataTable(tableRef2.current, {
    //   // iDisplayLength: 1000,
    //   orderCellsTop: true,
    //   fixedHeader: true,
    //   initComplete: function () {

    //     $('.datatable2 .filters').remove();
    //   },
    //   // searching: false,
    //   paging: false,
    //   order: [[ 0, 'desc' ]],
    //   data:data.tls,
    //   "destroy" : true,
    //   columns: [
    //     { title: "TL Name",data:function(d){return`<label class="text-light-primary">${d.name}</label>`;},name:'name'},
    //     { title: "Email", data:function(d){return`<label class="text-dark-info fw-light">${d.email}</label>`;},name:'email' },
    //     { title: "Final INR",data:function(d){return`<label class="text-secondary fw-light ">${Number(d.final_inr).toLocaleString('en-IN')}</label>`;},name:'final_inr'},
    //     // { title: "Total Business", data:function(d){ return `<label class="text-dark ">$ ${Number(d.sum).toLocaleString('en-IN')}</label>`; },name:'sum' }
    //    ],
    // });
  }, [data]);
  const handleChange = ([newStartDate, newEndDate]) => {
    gState.inrPayments.set({startDate:newStartDate,endDate:newEndDate});

    // setStartDate(newStartDate);
    // setEndDate(newEndDate);
  };
 
  return (
            <div className="all_pages_body pt-3 mb-100">
            
              <div className='container '>
                <div className="row ">

                  <div className="col-12 mt-3">
                    <div className="card bg-light text-dark border-0 shadow-sm p-2">
                      <div className="card-content">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-7">
                              <h3>Broker INR Amount</h3>
                            </div>
                            <div className="col-5">
                              <div className="float-end">
                                <div className=" datepicker_section">
                                  <DatePicker
                                      selected={startDate}
                                      onChange={handleChange}
                                      selectsRange
                                      startDate={startDate}
                                      endDate={endDate}
                                      dateFormat="MM/yyyy"
                                      showMonthYearPicker
                                    />
                                    <FontAwesomeIcon className="datepicker_icon" icon={faCalendarDays} />
                                </div>
                                 {/* <span className="px-2">From:</span> <input type="month" value={startDate} onChange={(event)=>{setStartDate(event.target.value); if(endDate<event.target.value){setEndDate(event.target.value)}}} />
                                <span className="px-2">To: </span>
                                <input type="month" value={endDate} onChange={(event)=>{if(startDate<event.target.value){setEndDate(event.target.value)}else{setEndDate(startDate)}}} /> */}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='container mt-3 py-3 '>
                  <div className="row">
                    
                            {data.data.length>0?
                              data.data.map((item,key)=>{
                                return(
                                  <>
                                <div className="col-md-2 my-3 " key={key}>
                                  <div class="card shadow-sm">
                                    <Link to={`/admin/broker/inr-payments/detail/${item.user_id}`}>
                                      <div class="card-body position-relative">
                                       {/* <small className="float-end rounded-top  text-center text-white  bg-orange fw-bold">{item.manager_total>0?'Manager':(item.tl_total>0?"TL":'Broker')}</small> */}

                                        <FontAwesomeIcon icon={faCircleUser}  className="float-start fs-1 me-2 my-1 text-army-green"/>
                                        <h6 class="card-title text-info"> {item.name.substring(0, 11)}</h6>
                                        <p class="card-text mb-0 text-royal-green hover-text" > ₹ <b>{Number((item.total+item.manager_total+item.tl_total)).toLocaleString('en-IN')}</b> 
                                          <span class="tooltip-text" id="bottom">
                                            <small>SELF: ₹ {(Number(item.total).toLocaleString('en-IN'))}</small><br/>
                                            {item.manager_total>0?<small>TM: ₹ {Number(item.manager_total).toLocaleString('en-IN')}</small>:""}
                                            {item.tl_total>0?<small>TL: ₹ {Number(item.tl_total).toLocaleString('en-IN')}</small>:""}
                                          </span>
                                        </p>
                                        
                              
                                      </div>
                                    </Link>
                                  </div>
                               </div>

                                </>)

                              })
                            :<div className="col-md-12 my-3 "><div class="card shadow-sm"> <div class="card-body text-center"> No Data Found.</div></div></div>}
                      
                  </div>
              </div>
            </div>
  )
}


