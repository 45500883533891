import React, { Component } from "react";
import Chart from "react-apexcharts";

class barsChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
          
      series: [{
        name:'Total Business',
        data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
      }],
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            formatter: undefined,
        },
          y: {
            show: true,
            formatter: function(value){
              return '$' + Number(value).toLocaleString('en-IN');
            },
        }
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return '$' + Number(value).toLocaleString('en-IN');
          }
        },
        xaxis: {
          categories: this.props.labels??[],
          
          labels: {
            formatter: function (value) {
              return '$' + Number(value).toLocaleString('en-IN');
            },
            style: {
              fontSize: '12px',
              fontWeight: 800,
              color:'red'
            }
          }
          },
        
        yaxis: {
          labels: {
            style: {
              fontSize: '12px',
              fontWeight: 800,
              color:'red'
            }
          },
        
        },
        
        grid: {
          show: true,
          strokeDashArray: 7,
          xaxis: {
            lines: { 
              show: false  //or just here to disable only y axis
             },
            labels: {
              formatter: function (value) {
                return '$' + Number(value).toLocaleString('en-IN');
              }
            }
           },
          yaxis: {
            lines: { 
              show: true,  //or just here to disable only y axis
             }
             
           },
        },
      },
    
    
    };
    
    }

    componentDidUpdate(prevProps) {
      if (prevProps.series !== this.props.series) {

        this.setState({
          options: {
            ...this.state.options,
            xaxis: {
              ...this.state.options.xaxis,
              categories:  this.props.labels,
              labels: {
                style: {
                  fontSize: '10px',
                  fontWeight: 500,
                  color:'red'
                }
              }
            }
          }
          
        });

      //  console.log(this.state);
      }
    }
  render() {
    return (
      <>
      
              <Chart
                options={this.state.options}
                series={[{name:'Total Business',data: this.props.series}]}
                type="bar"
                height="500"

                />
      </>
    );
  }
}
 
export default barsChart;